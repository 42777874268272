import React from "react";

const Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 1024 1024" fill="none">
      <rect width="1024" height="1024" rx="204.8" fill="#FFBC0D" />
      <rect x="343" y="250.875" width="143.36" height="143.36" rx="71.68" fill="white" />
      <path d="M414.8 320.3C414.8 301.743 429.843 286.7 448.4 286.7C466.957 286.7 482 301.743 482 320.3C482 338.857 466.957 353.9 448.4 353.9C429.843 353.9 414.8 338.857 414.8 320.3Z" fill="black" />
      <rect x="368.6" y="404.475" width="92.16" height="368.64" rx="46.08" fill="black" />
      <rect width="143.36" height="143.36" rx="71.68" transform="matrix(-1 0 0 1 680.92 250.875)" fill="white" />
      <path d="M609.12 320.3C609.12 301.743 594.077 286.7 575.52 286.7C556.963 286.7 541.92 301.743 541.92 320.3C541.92 338.857 556.963 353.9 575.52 353.9C594.077 353.9 609.12 338.857 609.12 320.3Z" fill="black" />
      <rect x="563.16" y="404.475" width="92.16" height="368.64" rx="46.08" fill="black" />
    </svg>
  );
};

export default Logo;

// <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
//   <rect width="58" height="58" rx="12.6875" fill="white" />
//   <rect x="16.1668" y="39.4287" width="24.7582" height="10.8187" rx="2.32" transform="rotate(-45 16.1668 39.4287)" fill="#41B7F3" />
//   <path d="M34.1483 36.7549L17.7238 20.3304C16.8178 19.4244 16.8178 17.9555 17.7238 17.0494L22.2111 12.5621C23.1172 11.6561 24.5861 11.6561 25.4921 12.5621L40.2761 27.3461C41.1821 28.2521 41.1821 29.7211 40.2761 30.6271L34.1483 36.7549Z" fill="#000000" />
//   <path d="M25.1461 30.4556L34.1502 36.7584L26.4967 29.105L25.1461 30.4556Z" fill="#003169" />
// </svg>