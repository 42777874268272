import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

import ChevronRightIcon from "../../assets/images/chevronRightIcon";

interface BreadcrumbItem {
  title: string | React.ReactNode;
  href?: string;
}

interface BreadcrumbsProps {
  items: Array<BreadcrumbItem>;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  return (
    <Breadcrumb separator={<ChevronRightIcon />} className="BreadcrumbContainer">
      {items.map((item, index) => {
        const isLastItem = index === items.length - 1;
        return (
          <Breadcrumb.Item key={index}>
            <Link to={item.href || "/"}>
              {isLastItem ? <span className="BreadcrumbsButton">{item.title}</span> : <span className="item-title"> {item.title} </span>}
            </Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;