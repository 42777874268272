import { createSlice } from "@reduxjs/toolkit";

import { NotificationData } from "../../components/notification/NotificationTypes";

interface IProps {
  dataForOrders: NotificationData | null;
  notifications: NotificationData[];
  isNotificationDrawerOpen: boolean
}

const initialState: IProps = {
  dataForOrders: null,
  notifications: [],
  isNotificationDrawerOpen: false,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    notificationOrderData: (state, { payload }) => {
      state.dataForOrders = payload;
    },
    clearNotificationdata: (state) => {
      state.notifications = [];
    },
    allNotificationData: (state, { payload }) => {
      state.notifications = payload;
    },
    setNotificationData: (state, { payload }) => {
      state.notifications = state.notifications.map((data) => data.notificationHistoryId === payload.notificationHistoryId ? { ...data, isRead: true } : data);
    },
    readAllNotifications: (state) => {
      state.notifications = state.notifications.map((data) => ({ ...data, isRead: true, isNew: false }));
    },
    handleNotificationDrawer: (state) => {
      state.isNotificationDrawerOpen = !state.isNotificationDrawerOpen;
    },
  },
});

export const { notificationOrderData, allNotificationData, handleNotificationDrawer, clearNotificationdata, setNotificationData, readAllNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;