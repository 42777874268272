import React from "react";

import CommonModal from "../../components/commonModal";
import { UnblockIcon } from "../../assets/images/icons";
import { ACCOUNT_SETTINGS } from "../../utils/constant";
// import { useAppDispatch } from "../../hooks/useAppDispatch";

interface IUnBlockConnectionPopupProps {
  setToggle: (e: boolean) => void;
  toggle: boolean;
  onSubmit: any;
}

const UnBlockConnectionPopup = ({ setToggle, toggle, onSubmit }: IUnBlockConnectionPopupProps) => {
  // const dispatch = useAppDispatch();

  const handleClose = () => {
    setToggle(false);
  };
  const handleUnblock = () => {
    setToggle(false);
    onSubmit();
  };
  return (
    <CommonModal
      close={handleClose}
      open={toggle}
      closeIcon
      className="unblock-connection-popup-modal"
    >
      <div className="flex direction-column gap-5 alignCenter justifyCenter">
        <div className="block-icon flex alignCenter justifyCenter rounded-14">
          <UnblockIcon />
        </div>
        <h3 className="title-3">{ACCOUNT_SETTINGS.CONNECTION_UNBLOCK_POPUP.TITLE}</h3>
        <p className="caption">{ACCOUNT_SETTINGS.CONNECTION_UNBLOCK_POPUP.DESCRIPTION}</p>
        <div className="flex justifyCenter gap-3 buttons-container">
          <button className="blocked_button rounded-14" onClick={handleClose}>{ACCOUNT_SETTINGS.CONNECTION_UNBLOCK_POPUP.BUTTON_TEXT_1}</button>
          <button className="secondry_button rounded-14" onClick={handleUnblock}>{ACCOUNT_SETTINGS.CONNECTION_UNBLOCK_POPUP.BUTTON_TEXT_2}</button>
        </div>
      </div>
    </CommonModal>
  );
};

export default UnBlockConnectionPopup;