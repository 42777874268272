import React from "react";
import Skeleton from "react-loading-skeleton";

const UserInfoSkeleton = () => {
  return (
    <div className="user-box gap-2 flex alignCenter">
      <div className="img-box">
        <Skeleton circle width={44} height={47} />
      </div>
      <div className="customer-info flex direction-column">
        <span><Skeleton width={170} /></span>
        <span className="user-name"><Skeleton width={100} /></span>
      </div>
    </div>
  );
};

export default UserInfoSkeleton;