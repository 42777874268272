import React from "react";

import { LogoEyeIcon } from "../../assets/images/icons";

const ComingSoon = () => {
  return (
    <div className="card commin-soon-wrapper">
      <div className="coming-soon-container">
        <div className="center-container textCenter">
          <div className="icon-box"><LogoEyeIcon /></div>
          <div className="content-container p-4">
            <div className="title">Coming Soon</div>
            <div className="info">Stay Tuned with us! Will get back to you soon.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;