import { createSlice } from "@reduxjs/toolkit";

import { getBlockedConnectionsList, getNotificationModulesList, getProductsManagementData, getRoleAccessData, getUserModulesActivity, getUserRoleDetails, getUserRoleList } from "../services/accountSetting";

interface Props {
  isLoading: boolean,
  isSuccess: boolean,
  roles: {
    isLoading: boolean,
    isSuccess: boolean,
    data: any[],
  },
  selectedRole: any[];
  usersList: {
    data: {
      roleId: number,
      roleName: string,
      user: {
        firstName: string,
        lastName: string
      }
    }[];
    isLoading: boolean;
    isSuccess: boolean;
  };
  userDetails: {
    data: any;
    isLoading: boolean;
    isSuccess: boolean;
  };
  loggedInUserRole: {
    data: any;
    isLoading: boolean;
    isSuccess: boolean;
    message: string;
  };
  productManagementList: {
    data: {
      productSettingId: number;
      moduleName: string;
      isDisable: boolean;
      isActive: boolean;
      isDelete: boolean;
      createdOn: string;
      modifiedOn: string;
    }[] | any[];
    isLoading: boolean;
    isSuccess: boolean;
    message: string;
  };
  blockedConnectionsList: {
    data: any;
    isLoading: boolean;
    isSuccess: boolean;
    message: string;
  };
  notificationModulesList: {
    data: any;
    isLoading: boolean;
    isSuccess: boolean;
    message: string;
  };
}

const initialState: Props = {
  isLoading: false,
  isSuccess: false,
  roles: {
    isLoading: false,
    isSuccess: false,
    data: [],
  },
  selectedRole: [],
  usersList: {
    data: [],
    isLoading: false,
    isSuccess: false,
  },
  userDetails: {
    data: {},
    isLoading: false,
    isSuccess: false,
  },
  loggedInUserRole: {
    data: [],
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  productManagementList: {
    data: [],
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  blockedConnectionsList: {
    data: [],
    isSuccess: false,
    isLoading: false,
    message: "",
  },
  notificationModulesList: {
    data: [],
    isSuccess: false,
    isLoading: false,
    message: "",
  },
};

const accountSettingSlice = createSlice({
  name: "accountSetting",
  initialState,
  reducers: {
    accessTable: (state, { payload }) => {
      state.selectedRole = payload;
    },
    clearForm: (state) => {
      state.userDetails = initialState.userDetails;
      state.roles = initialState.roles;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserModulesActivity.pending, (state, { payload }) => {
      state.roles.isLoading = true;
      state.roles.isSuccess = false;
    });
    builder.addCase(getUserModulesActivity.fulfilled, (state, { payload }) => {
      state.roles.isLoading = false;
      state.roles.isSuccess = true;
      state.roles.data = payload?.data;
    });
    builder.addCase(getUserModulesActivity.rejected, (state, { payload }) => {
      state.roles.isLoading = false;
      state.roles.isSuccess = false;
      state.roles.data = [];
    });

    builder.addCase(getUserRoleList.pending, (state, { payload }) => {
      state.usersList.isLoading = true;
      state.usersList.isSuccess = false;
    });
    builder.addCase(getUserRoleList.fulfilled, (state, { payload }) => {
      state.usersList.isLoading = false;
      state.usersList.isSuccess = true;
      state.usersList.data = payload?.data;
    });
    builder.addCase(getUserRoleList.rejected, (state, { payload }) => {
      state.usersList.isLoading = false;
      state.usersList.isSuccess = false;
      state.usersList.data = [];
    });
    
    builder.addCase(getUserRoleDetails.pending, (state, { payload }) => {
      state.userDetails.isLoading = true;
      state.userDetails.isSuccess = false;
    });
    builder.addCase(getUserRoleDetails.fulfilled, (state, { payload }) => {
      state.userDetails.isLoading = false;
      state.userDetails.isSuccess = true;
      state.userDetails.data = payload?.data;
    });
    builder.addCase(getUserRoleDetails.rejected, (state, { payload }) => {
      state.userDetails.isLoading = false;
      state.userDetails.isSuccess = false;
      state.userDetails.data = [];
    });

    builder.addCase(getRoleAccessData.pending, (state, { payload }) => {
      state.loggedInUserRole.isLoading = true;
      state.loggedInUserRole.isSuccess = false;
    });
    builder.addCase(getRoleAccessData.fulfilled, (state, { payload }) => {
      state.loggedInUserRole.isLoading = false;
      state.loggedInUserRole.isSuccess = true;
      state.loggedInUserRole.data = payload?.data;
      state.loggedInUserRole.message = payload?.message;
    });
    builder.addCase(getRoleAccessData.rejected, (state, { payload }) => {
      state.loggedInUserRole.isLoading = false;
      state.loggedInUserRole.isSuccess = false;
      state.loggedInUserRole.data = [];
    });

    builder.addCase(getProductsManagementData.pending, (state, { payload }) => {
      state.productManagementList.isLoading = true;
      state.productManagementList.isSuccess = false;
      state.productManagementList.data = [];
    });
    builder.addCase(getProductsManagementData.fulfilled, (state, { payload }) => {
      state.productManagementList.isLoading = false;
      state.productManagementList.isSuccess = true;
      state.productManagementList.data = payload?.data;
      state.productManagementList.message = payload?.message;
    });
    builder.addCase(getProductsManagementData.rejected, (state, { payload }) => {
      state.productManagementList.isLoading = false;
      state.productManagementList.isSuccess = false;
      state.productManagementList.data = [];
    });

    builder.addCase(getBlockedConnectionsList.pending, (state, { payload }) => {
      state.blockedConnectionsList.isLoading = true;
      state.blockedConnectionsList.isSuccess = false;
      state.blockedConnectionsList.data = [];
    });
    builder.addCase(getBlockedConnectionsList.fulfilled, (state, { payload }) => {
      state.blockedConnectionsList.isLoading = false;
      state.blockedConnectionsList.isSuccess = true;
      state.blockedConnectionsList.data = payload?.data;
      state.blockedConnectionsList.message = payload?.message;
    });
    builder.addCase(getBlockedConnectionsList.rejected, (state, { payload }) => {
      state.blockedConnectionsList.isLoading = false;
      state.blockedConnectionsList.isSuccess = false;
      state.blockedConnectionsList.data = [];
    });

    // Notification Settings Slice
    builder.addCase(getNotificationModulesList.pending, (state, { payload }) => {
      state.notificationModulesList.isLoading = true;
      state.notificationModulesList.isSuccess = false;
      state.notificationModulesList.data = [];
    });
    builder.addCase(getNotificationModulesList.fulfilled, (state, { payload }) => {
      state.notificationModulesList.isLoading = false;
      state.notificationModulesList.isSuccess = true;
      state.notificationModulesList.data = payload?.data;
      state.notificationModulesList.message = payload?.message;
    });
    builder.addCase(getNotificationModulesList.rejected, (state, { payload }) => {
      state.notificationModulesList.isLoading = false;
      state.notificationModulesList.isSuccess = false;
      state.notificationModulesList.data = [];
    });
  },
});

export const { accessTable, clearForm } = accountSettingSlice.actions;
export default accountSettingSlice.reducer;