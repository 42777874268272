import React, { useEffect } from "react";

import Page from "../../components/page";
import CommonTable from "./commonTable";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getProductsManagementData } from "../../redux/services/accountSetting";
import { useAppSelector } from "../../hooks/useAppSelector";
import { ACCOUNT_SETTINGS } from "../../utils/constant";

const ProductSetting = () => {
  const dispatch = useAppDispatch();
  const { productManagementList } = useAppSelector((state) => state.accountSetting);

  useEffect(() => {
    dispatch(getProductsManagementData());
  }, [dispatch]);

  return (
    <Page
      title={[]}
      className="product-settings-page"
    >
      <div className="product-settings-header">
        <h3 className="title-3">{ACCOUNT_SETTINGS.PRODUCT_MANAGEMENT_PAGE.PAGE_TITLE}</h3>
        <p className="caption">{ACCOUNT_SETTINGS.PRODUCT_MANAGEMENT_PAGE.TEXT}</p>
      </div>
      <div className="product-settings-body">
        <div className="product-settings-table">
          <CommonTable isLoading={productManagementList?.isLoading} title={ACCOUNT_SETTINGS.PRODUCT_MANAGEMENT_PAGE.TABLE_TITLE} data={productManagementList?.data} />
        </div>
      </div>
    </Page>
  );
};

export default ProductSetting;