import React from "react";

import { NoUser } from "../../assets/images";
import { PlusCircleFilled } from "../../assets/images/icons";
import { useAppSelector } from "../../hooks/useAppSelector";

interface Props {
  onClick?: any;
}

const NoDataPage = ({ onClick }: Props) => {
  const { connection } = useAppSelector(state => state.connections);

  return (
    <div className="no-data-page h-100">
      <div className="no-data-container">
        <div className="center-box flex direction-column alignCenter">
          <div className="bg-img">
            <NoUser />
          </div>
          <div className="invite-text-container">
            <h4 className="title-4">Invite your {connection.total > 0 ? "" : "first"} connection</h4>
            <p className="caption">Invite your {connection.total > 0 ? "" : "first"} connection to kickstart your business! Reach out, introduce yourself, and start building meaningful relationships today!</p>
            <button className="primary" onClick={onClick}><PlusCircleFilled /> Invite</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoDataPage;