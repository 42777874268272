import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ordersDataPagination: {
    currentPage: 1,
    pageSize: 10,
  },
  productListPagination: {
    currentPage: 1,
    pageSize: 10,
  },
  connectionOrdersPagination :{
    currentPage: 1,
    pageSize: 10,  
  },
  specialPricePagination :{
    currentPage: 1,
    pageSize: 10,    
  },
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setOrderDataPagination: (state, { payload }) => {
      state.ordersDataPagination = payload;
    },
    setProductListPagination: (state, { payload }) => {
      state.productListPagination = payload;
    },
    setConnectionOrdersPagination: (state, { payload }) => {
      state.connectionOrdersPagination = payload;
    },
    setSpecialPricePagination: (state, { payload }) => {
      state.specialPricePagination = payload;
    },
  },
});

export const { setOrderDataPagination, setProductListPagination, setConnectionOrdersPagination, setSpecialPricePagination } = paginationSlice.actions;
export default paginationSlice.reducer;