import { SettingOutlinedIcon } from "./settingOutlinedIcon";
import { SearchOutlinedIcon } from "./searchOutlinedIcon";
import { BellOutlinedIcon } from "./bellOutlinedIcon";
import LogoIcon from "./logoIcon.png";
import ProfileIcon from "./profileIcon.png";
import Logo from "./logoIcon";
import HomeIcon from "./homeIcon";
import SearchIcon from "./searchIcon";
import OfferBG from "./offerBG";
import MainLogo from "./logo";
import SecureIcon from "./secureIcon";
import IndiaFlagIcon from "./indiaFlagIcon";
import VerifiedIcon from "./verifiedIcon";
import CrossRoundedIcon from "./crossRoundedIcon";
import StatusIcon from "./statusIcon";
import DeleteOutlinedIcon from "./DeleteOutlinedIcon";
import UploadOutlinedIcon from "./UploadOutlinedIcon";
import CrossIcon from "./crossIcon";
import MoreIconsDots from "./moreIconsDots";
import RoundClockIcon from "./roundClockIcon";
import StopIcon from "./stopIcon";
import CartBagIcon from "./cartBag";
import LocationIcon from "./locationIcon";
import NoUser from "./NoUser";
import NoDataIcon from "./Search.png";
import ProductsGroupIcon from "./productsGroup";
import productPlaceholderImage from "./productPlaceholderImage.jpg";
import PlusIcons from "./plusIcons";
import MinusIcon from "./minusIcon";
import CartIcon from "./cartIcon";
import User1 from "./users/user-1.png";
import User2 from "./users/user-2.png";
import User3 from "./users/user-3.png";
import User4 from "./users/user-4.png";
import User5 from "./users/user-5.png";
import User6 from "./users/user-6.png";
import User7 from "./users/user-7.png";
import User8 from "./users/user-8.png";
import SquareLogo from "./app-logo.png";
import HorizontalLogo from "./web-logo.png";
import BiizlineLogo from "./biizlineLogo.png";
import HeroImage from "./heroImage.png";
import HeroImageMobile from "./HeroImageMobile.png";
import Testimonial1 from "./testimonial1.png";
import Testimonial2 from "./testimonial2.png";
import Testimonial3 from "./testimonial3.png";
import Testimonial4 from "./testimonial4.png";
import Img1 from "./landingPage/img1.png";
import Img2 from "./landingPage/img2.png";
import Img3 from "./landingPage/img3.png";
import Img4 from "./landingPage/img4.png";
import Img5 from "./landingPage/img5.png";
import Img6 from "./landingPage/img6.png";
import Img7 from "./landingPage/img7.png";
import Img8 from "./landingPage/img8.png";
import Img9 from "./landingPage/img9.png";
import Img10 from "./landingPage/img10.png";
import Img11 from "./landingPage/img11.png";
import EmptyStateSpecialPriceList from "./emptyStateSpecialPriceList.png";
import AppQRCode from "./qrcode.png";
import SuccessIcon from "./wp_success.png";
import { CheckMarkIcon } from "./checkMarkIcon";
import PhoneOutlinedIcon from "./phoneOutlinedIcon";
import EmailOutlinedIcon from "./emailOutlinedIcon";
import PromoCodeIcon from "./promoCodeIcon";
import CrossIcon2 from "./crossIcon2";
import SubscriptionPoster from "./subscribe-poster.svg";

export {
  SettingOutlinedIcon,
  SearchOutlinedIcon,
  BellOutlinedIcon,
  Logo,
  HomeIcon,
  SearchIcon,
  OfferBG,
  MainLogo,
  SecureIcon,
  IndiaFlagIcon,
  VerifiedIcon,
  CrossRoundedIcon,
  CrossIcon,
  LogoIcon,
  ProfileIcon,
  StatusIcon,
  DeleteOutlinedIcon,
  UploadOutlinedIcon,
  MoreIconsDots,
  RoundClockIcon,
  StopIcon,
  CartBagIcon,
  LocationIcon,
  NoUser,
  NoDataIcon,
  ProductsGroupIcon,
  productPlaceholderImage,
  PlusIcons,
  MinusIcon,
  CartIcon,
  User1,
  User2,
  User3,
  User4,
  User5,
  User6,
  User7,
  User8,
  SquareLogo,
  HorizontalLogo,
  BiizlineLogo,
  HeroImage,
  HeroImageMobile,
  Testimonial1,
  Testimonial2,
  Testimonial3,
  Testimonial4,
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
  Img10,
  Img11,
  EmptyStateSpecialPriceList,
  AppQRCode,
  SuccessIcon,
  CheckMarkIcon,
  PhoneOutlinedIcon,
  EmailOutlinedIcon,
  PromoCodeIcon,
  CrossIcon2,
  SubscriptionPoster,
};