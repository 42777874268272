import { createSlice } from "@reduxjs/toolkit";

import { getExpandedTableData } from "../services/table";

const initialState = {
  expandedTable: {
    isLoading: false,
    isSuccess: false,
    data: [],
    message: "",
  },
};

const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getExpandedTableData.pending, (state) => {
      state.expandedTable.data = initialState.expandedTable.data;
      state.expandedTable.isLoading = true;
      state.expandedTable.isSuccess = false;
    });
    builder.addCase(getExpandedTableData.fulfilled, (state, { payload }: any) => {
      state.expandedTable.isSuccess = true;
      state.expandedTable.isLoading = false;
      state.expandedTable.data = payload?.data;
      state.expandedTable.message = payload?.message;
    });
    builder.addCase(getExpandedTableData.rejected, (state, { payload }: any) => {
      state.expandedTable.data = payload;
      state.expandedTable.isSuccess = false;
      state.expandedTable.isLoading = false;
      state.expandedTable.message = payload?.message;
    });
  },
});

export default tableSlice.reducer;