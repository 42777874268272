import React from "react";

const MinusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M14.9766 8.36914H3.02344C2.95039 8.36914 2.89062 8.42891 2.89062 8.50195V9.49805C2.89062 9.57109 2.95039 9.63086 3.02344 9.63086H14.9766C15.0496 9.63086 15.1094 9.57109 15.1094 9.49805V8.50195C15.1094 8.42891 15.0496 8.36914 14.9766 8.36914Z" fill="#fff" />
    </svg>
  );
};

export default MinusIcon;