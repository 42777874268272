import React from "react";

interface IEmailInputProps {
  required?: boolean;
}

export const EmailInput = ({ required }: IEmailInputProps) => {
  return (
    <div className="email-input-wrapper">
      <label htmlFor="" className="email-label">
        <div className="label-text flex alignStart">Email Address {required ? <span className="require-icon"> *</span> : ""}</div>
        <div className="input-container flex alignCenter justifyBetween rounded-8">
          <input type="email" name="email" className="email-input" placeholder="Enter your email address" />
          <div className="verify-button">
            <button className="verify" type="button">Verify</button>
          </div>
        </div>
      </label>
    </div>
  );
};