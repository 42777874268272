import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Popover, Spin } from "antd";
import OneSignal from "react-onesignal";

import socket from "../../socket";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { detectDevice, getProfileIcon } from "../../utils/helper";
import { handleNotificationDrawer, setNotificationData } from "../../redux/slices/notificationSlice";
import { useAppSelector } from "../../hooks/useAppSelector";
import { DownArrow, SettingOutlinedFilledIcon } from "../../assets/images/icons";
import CompanyListAndSetting from "../companyListAndSetting";
import { BellOutlinedIcon, BiizlineLogo } from "../../assets/images";
import CountCapsule from "../countCapsule";
import "../../assets/styles/common.scss";
import { getSubscriptionPayloadData } from "../../redux/services/subscription";
import { SubscriptionModules } from "../../utils/constant";

export const Header = () => {
  const [isToggleCompany, setToggleCompany] = useState(false);
  const [isOpenPopover, setIsOpenPopover] = useState({
    isSetting: false,
  });
  const [companyRestrictionData, setCompanyRestrictionData] = useState();
  const [notificationCount, setNotificationCount] = useState({
    newNotifications: "",
    totalNotifications: "",
  });
  const [navItems, setNavItems] = useState([
    {
      icon: "",
      text: <p>Home</p>,
      url: "/dashboard",
      checked: true,
      isDisable: true,
    },
    {
      icon: "",
      text: <span className="flex justifyCenter alignCenter gap-2"><p>Orders</p></span>,
      // text: <span className="flex justifyCenter alignCenter gap-2">Orders <span className="new-chip">New</span></span>,
      url: "/orders",
      checked: true,
      isDisable: true,
    },
    {
      icon: "",
      text: <p>Special Price List</p>,
      url: "/special-price-list",
      checked: true,
    },
    {
      icon: "",
      text: <span className="flex justifyCenter alignCenter gap-2"><p>Connections</p></span>,
      // text: <span className="flex justifyCenter alignCenter gap-2">Connections <CountCapsule count={10} position="relative" style={{ border: "none" }} /></span>,
      url: "/connection",
      checked: true,
    },
    {
      icon: "",
      text: <p>Products</p>,
      url: "/products",
      checked: true,
      isDisable: true,
    },
    // {
    //   icon: "",
    //   text: "Chat",
    //   url: "/chat",
    //   checked: true,
    //   isDisable: true,
    // },
    // {
    //   icon: "",
    //   text: "Discount & Offers",
    //   url: "/discount&offers",
    //   checked: true,
    // },
    // {
    //   icon: "",
    //   text: "Analytics",
    //   url: "/analytics",
    //   checked: true,
    // },
    // {
    //   icon: "",
    //   text: "Chat",
    //   url: "/chat",
    //   checked: true,
    //   isDisable: true,
    // },
    // {
    //   icon: "",
    //   text: "Discount & Offers",
    //   url: "/discount&offers",
    //   checked: true,
    // },
  ]);

  const [subscribePopup, setSubscribePopup] = useState(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { userDetails } = useAppSelector(state => state.common);
  const companyDetails = userDetails.data?.company;
  const { userId } = userDetails.data?.user || { userId: 0 };
  const token = localStorage.getItem("token");
  const navigate = useNavigate();


  useEffect(() => {
    if (location?.pathname !== `/settings/${userId}/subscribe`) {
      setSubscribePopup(true);
    } else {
      setSubscribePopup(false);
    }
  }, [location, userId]);

  useEffect(() => {
    if (detectDevice()?.type === "Desktop" && userId && token) {
      dispatch(getSubscriptionPayloadData({ module: SubscriptionModules.COMPANY })).then((result) => {
        if (result?.payload?.status === 200) {
          setCompanyRestrictionData(result?.payload?.data);
        }
      }).catch((error) => console.error(error));
    }
  }, [dispatch, token, userId]);

  useEffect(() => {
    socket.emit("notificationHistory", companyDetails?.companyId);
    socket.emit("newNotificationCount", companyDetails?.companyId);
    socket.on("newNotificationCountResponse", (data: any) => {
      document.title = data ? `(${data}) Biizline` : "Biizline";
      setNotificationCount((prev) => ({
        newNotifications: data,
        totalNotifications: prev.totalNotifications,
      }));
    });

    socket.on("readNotificationResponse", (data: any) => {
      if (data?.isRead) {
        dispatch(setNotificationData(data));
        socket.emit("newNotificationCount", companyDetails?.companyId);
      } else {
        console.error("read notification error");
      }
    });

    socket.emit("allNotificationCount", companyDetails?.companyId);

    socket.on("allNotificationCountResponse", (data: any) => {
      setNotificationCount((prev) => ({
        newNotifications: prev.newNotifications,
        totalNotifications: data,
      }));
    });
    socket.on(`webDeviceLogedOut_${userId}`,(data) => {
      if(data){
        OneSignal.logout();
        dispatch({ type: "RESET_STATE" });
        // removeFCMToken();
        localStorage.clear();
        setTimeout(() => {
          navigate("/login", { state: { step: 0 } });
          // window.location.href = "/login";
        }, 50);
      }
    });

    return () => {
      socket.off("newNotificationCountResponse");
      socket.off("readNotificationResponse");
      socket.off("allNotificationCountResponse");
      socket.off(`webDeviceLogedOut_${userId}`);
    };
  }, [socket, companyDetails?.companyId, userId]);

  const handleToggleNotification = () => {
    socket.emit("notificationHistory", companyDetails?.companyId);
    dispatch(handleNotificationDrawer());
  };

  const handleOpenChange = () => {
    setToggleCompany(!isToggleCompany);
  };

  const handleChange = () => {
    setIsOpenPopover((prev) => ({
      ...prev,
      isSetting: !isOpenPopover.isSetting,
    }));
  };

  return (
    <>
      <header className="header flex alignCenter justifyBetween w-100">
        <div className="flex gap-4 justifyBetween alignCenter h-100 overflow-auto w-100 container">
          <NavLink to={"/"} className="logo-icon">
            <img src={BiizlineLogo} alt="Logo" loading="lazy"/>
            {/* <SquareLogo /> */}
          </NavLink>
          {/* <Button type="button" className="primary" onClick={() => navigate("/vendors", { state: { vendorId: null } })}> <PlusCircleOutlined /> Place Order </Button> */}
          {/* <Button type="button" className="primary" onClick={() => setToggleSelectVendors(true)}> <PlusCircleOutlined /> Place Order </Button> */}
          <nav className="navbar flex justifyStart">
            <ul className="flex gap-2 nav-items">
              {navItems && navItems?.map((item, index) => {
                return (
                  item.checked ? <li className="item relative" key={index}>
                    <NavLink to={item.url} className={"flex alignCenter justifyCenter"}>{item.icon}{item.text}</NavLink>
                  </li> : ""
                );
              })}
            </ul>
            {/* <Popover content={content} trigger="click">
            <Button type="button" name="other" className="text primary flex alignCenter"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
              <path d="M2.16669 4.33332C2.16669 2.86056 3.36059 1.66666 4.83335 1.66666C6.30611 1.66666 7.50002 2.86056 7.50002 4.33332C7.50002 5.80608 6.30611 6.99999 4.83335 6.99999C3.36059 6.99999 2.16669 5.80608 2.16669 4.33332Z" stroke="#0958D9" strokeWidth="1.5" />
              <path d="M9.5 11.6667C9.5 10.1939 10.6939 9 12.1667 9C13.6394 9 14.8333 10.1939 14.8333 11.6667C14.8333 13.1394 13.6394 14.3333 12.1667 14.3333C10.6939 14.3333 9.5 13.1394 9.5 11.6667Z" stroke="#000000" strokeWidth="1.5" />
              <path d="M14.8333 4.33332C14.8333 2.86056 13.6394 1.66666 12.1667 1.66666C10.6939 1.66666 9.5 2.86056 9.5 4.33332C9.5 5.80608 10.6939 6.99999 12.1667 6.99999C13.6394 6.99999 14.8333 5.80608 14.8333 4.33332Z" stroke="#000000" strokeWidth="1.5" />
              <path d="M7.50002 11.6667C7.50002 10.1939 6.30611 9 4.83335 9C3.36059 9 2.16669 10.1939 2.16669 11.6667C2.16669 13.1394 3.36059 14.3333 4.83335 14.3333C6.30611 14.3333 7.50002 13.1394 7.50002 11.6667Z" stroke="#000000" strokeWidth="1.5" />
            </svg> <span>Others</span></Button>
          </Popover> */}
          </nav>
          <div className="flex alignCenter justifyEnd buttons-container gap-6 w-100">
            {Object.entries(companyDetails || {}).length ?
              <Popover
                onOpenChange={handleOpenChange}
                open={isToggleCompany}
                rootClassName="select-company-popover"
                content={() => CompanyListAndSetting({ handleOpenChange })}
                trigger="click"
                placement="bottomRight">
                <button className="selected-company-btn gap-1">
                  {userDetails.isLoading ? <div className="loading-container"><Spin /></div> :
                    <>
                      <div className="image-container">
                        {companyDetails?.profileThumbnailImg ? <img src={companyDetails.profileThumbnailImg} alt="companyImage" /> : getProfileIcon(companyDetails?.name || "")}
                      </div>
                      <span className="company-name">{companyDetails?.name}</span>
                      <div className={isToggleCompany ? "action-dropdown active" : "action-dropdown"}>
                        <DownArrow />
                      </div>
                    </>}
                </button>
              </Popover> : <></>}
            {/* <div className="flex alignCenter get-premium-button">
              <DiamondIcon />
              <span className="fontWeight-600">Get Premium</span>
            </div> */}
            {/* <button className="invite-connection-button icon-button flex alignCenter justifyCenter">
              <UserFilledIcon />
              <CountCapsule count={10} />
            </button> */}
            {/* <ButtonIcon className="rounded-100"><SearchOutlinedIcon /></ButtonIcon> */}
            {(companyDetails || Object.entries(companyDetails || {})?.length) ?
              <button className="notification-icon flex alignCenter relative" onClick={() => handleToggleNotification()}>
                <BellOutlinedIcon />
                {notificationCount?.newNotifications ?
                  <CountCapsule
                    count={Number(notificationCount?.newNotifications)}
                    limit={10}
                  /> : <></>}
              </button> : <></>}
            {(companyDetails || Object.entries(companyDetails || {})?.length) ?
              <Popover
                onOpenChange={handleChange}
                open={isOpenPopover.isSetting}
                rootClassName="select-company-popover"
                content={() => CompanyListAndSetting({ handleOpenChange: handleChange, isSetting: true })}
                trigger="click"
                placement="bottomRight">
                <button className="setting-icon icon-button relative">
                  <SettingOutlinedFilledIcon />
                  {/* <CountCapsule count={0} /> */}
                </button>
              </Popover> : <></>}

            {/* <Popover
              rootClassName="profile-popover"
              content={
                <div className="profile-popup">
                  {((company && company?.length) || Object.entries(company)?.length) ? <div className="profile-info">
                    <ButtonIcon className="rounded-100 profile-image">
                      {(company?.profileThumbnailImg) ?
                        <img src={(company?.profileThumbnailImg)} alt="profile-icon" /> : getProfileIcon(company?.name)}
                    </ButtonIcon>
                    <div className="profile-text">
                      <h4 className="title-4">{company?.name}</h4>
                      <p className="profile-name">{(company?.createdBy?.firstName || ProfileData?.firstName) + " " + (company?.createdBy?.lastName || ProfileData?.lastName)}</p>
                    </div>
                  </div> : <></>}
                  <button
                    className="logout-button"
                    onClick={() => { setIsConfirmToggle(true); setToggleProfile(false); }}>
                    <LogoutOutlined />
                    Logout
                  </button>
                </div>
              }
              trigger="click"
              open={isToggleProfile}
              onOpenChange={handleProfileChange}
              placement="bottomRight">
              <div>
                <ButtonIcon className="rounded-100 profileIcon">
                  {(company?.profileThumbnailImg) ?
                    <img src={(company?.profileThumbnailImg)} alt="profile-icon" /> : company?.name ? getProfileIcon(company?.name) : <UserIcon />}
                </ButtonIcon>
              </div>
            </Popover> */}
          </div>
        </div>
      </header>
    </>
  );
};