import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container flex alignCenter justifyBetween">
        <div className="flex footer-left">
          <p className="copyrights-text">©2024 Biizline.com All Rights Reserved.</p>
          {/* <Link to={"/home/contact"}><button>Contact Us</button></Link> */}
          {/* <Link to={"/home/about"}><button>About Us</button></Link> */}
          <Link to={"/home/privacyPolicy"}><button>Privacy Policy</button></Link>
          <Link to={"/home/disclaimer"}><button>Disclaimer</button></Link>
          <Link to={"/home/tearmsandcondition"}><button>Terms and Condition</button></Link>
        </div>
        <p className="call-text"><Link to={"tel:+91 96 3800 2500"}>Call us: +91 96 3800 2500</Link></p>
      </div>
    </footer>
  );
};

export default Footer;