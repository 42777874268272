import React from "react";

const PlusIcons = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M8.50195 3.02344H9.49805C9.58659 3.02344 9.63086 3.06771 9.63086 3.15625V14.8437C9.63086 14.9323 9.58659 14.9766 9.49805 14.9766H8.50195C8.41341 14.9766 8.36914 14.9323 8.36914 14.8437V3.15625C8.36914 3.06771 8.41341 3.02344 8.50195 3.02344Z" fill="#fff" />
      <path d="M3.42187 8.36914H14.5781C14.6667 8.36914 14.7109 8.41341 14.7109 8.50195V9.49805C14.7109 9.58659 14.6667 9.63086 14.5781 9.63086H3.42187C3.33333 9.63086 3.28906 9.58659 3.28906 9.49805V8.50195C3.28906 8.41341 3.33333 8.36914 3.42187 8.36914Z" fill="#fff" />
    </svg>
  );
};

export default PlusIcons;