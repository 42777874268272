import React from "react";

import { SuccessIcon } from "../../assets/images";

const SuccessPopup = () => {
  return (
    <div className="success-box_wrapper">
      <div className="overlay"></div>
      <div className="success-box rounded-14">
        <div className="flex direction-column success-box__inner alignCenter gap-4">
          <img src={SuccessIcon} alt="create" />
          <h3 className="title-3">User created successfully.</h3>
        </div>
      </div>
    </div>
  );
};

export default SuccessPopup;