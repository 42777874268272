import React, { useEffect } from "react";
import { message, notification } from "antd";
import { useNavigate } from "react-router-dom";

import socket from "../../socket";
import { PushNotificationCard } from "../notification/notificationCard";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { notificationOrderData } from "../../redux/slices/notificationSlice";
import { NotificationData, NotificationTypes } from "../notification/NotificationTypes";
import { getConnectionList, getConnectionRequests, takeActionOnConnectionRequest } from "../../redux/services/connection";
import { useAppSelector } from "../../hooks/useAppSelector";
import { clearConnectionList } from "../../redux/slices/connectionSlice";

const PushNotification = () => {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { connectionActiveTab, connectionPayload } = useAppSelector((state) => state.connections);

  const { userDetails } = useAppSelector(state => state.common);

  const companyId = userDetails.data?.company?.companyId;

  const closePushNotification = () => {
    api.destroy();
  };

  const ListOfAllConnection = async () => {
    dispatch(clearConnectionList());
    const payload = {
      search: "",
      sort: "ASC",
      type: connectionActiveTab,
      limit: connectionPayload?.data,
      offset: 0,
    };
    await dispatch(getConnectionList(payload));
  };

  const getRecievedRequest = async () => {
    const payload = {
      search: "",
      limit: 0,
      offset: 0,
    };
    await dispatch(getConnectionRequests(payload));
  };

  const handleAction = async (action: string, type: NotificationTypes, notification: NotificationData) => {
    if (type === NotificationTypes.ORDER_PLACED_BY_CUSTOMER) {
      dispatch(notificationOrderData(notification));
      navigate("/orders");
      closePushNotification();
    } else if (type === NotificationTypes.CONNECTION_REQUEST_RECEIVED) {
      const payload = {
        requestId: notification.actionParams.requestId,
        action: action,
      };
      await dispatch(takeActionOnConnectionRequest(payload)).then((result) => {
        socket.emit("newNotificationCount", companyId);
        socket.emit("allNotificationCount", companyId);
        socket.emit("notificationHistory", companyId);
        if (result.payload.status === 200) {
          closePushNotification();
          getRecievedRequest();
          if (action === "Accept") {
            ListOfAllConnection();
            navigate(`/connection/${result.payload.data.connectionId}`);
          }
        } else {
          // showMessage("error", result?.payload.message || "Something went wrong");
          message.error(result?.payload.message || "Something went wrong");
        }
      });
    }
  };

  useEffect(() => {
    const handleNotification = (data: any) => {
      socket.emit("notificationHistory", companyId);
      socket.emit("allNotificationCount", companyId);
      socket.emit("newNotificationCount", companyId);
      if (data?.showPushNotification) {
        api.open({
          message: "",
          className: "push-notification",
          description: (
            <div className="push-notification-container">
              {/* {renderNotificationCard(data, closePushNotification)} */}
              {PushNotificationCard({ notification: data, handleAction: handleAction, companyId: companyId || 0 })}
            </div>
          ),
          placement: "topRight",
          duration: 5,
        });
      }
    };

    const notificationEvent = companyId ? `notification_${companyId}` : "notification";
    socket.on(notificationEvent, handleNotification);
    
    return () => {
      socket.off(notificationEvent, handleNotification);
    };
  }, [socket, companyId]);

  return (
    <div>{contextHolder}</div>
  );
};

export default PushNotification;