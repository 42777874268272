import React from "react";
import { Pagination as AntdPagination } from "antd";

interface Props {
  pageCount: number,
  total: number;
  showSizeChanger: boolean,
  onChange: (data: { page: number, pageSize: number }) => void;
  nextIcon?: string;
  prevIcon?: string;
  itemRender?: ((page: number, type: "page" | "prev" | "next" | "jump-prev" | "jump-next", element: React.ReactNode) => React.ReactNode) | undefined
  current: number;
  pageSize: number
}

const Pagination = ({ pageCount, total, showSizeChanger, onChange, nextIcon, prevIcon, itemRender, current, pageSize }: Props) => {

  const handleChange = (data: { page: number, pageSize: number }) => {
    onChange(data);
  };
  return (
    <AntdPagination
      showSizeChanger={showSizeChanger}
      onChange={(page, pageSize) => handleChange({ page, pageSize })}
      total={total}
      current={current}
      // nextIcon={nextIcon}
      // prevIcon={prevIcon}
      // defaultPageSize={pageSize}
      itemRender={itemRender}
    />
  );
};

export default Pagination;