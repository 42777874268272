import React from "react";

const CartBagIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path d="M7.87669 2.18359L4.80518 5.26359" stroke="#292D32" strokeWidth="1.27273" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.29 2.18359L16.3616 5.26359" stroke="#292D32" strokeWidth="1.27273" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.09814 7.14619C2.09814 5.57649 2.93814 5.44922 3.98178 5.44922H17.1842C18.2278 5.44922 19.0678 5.57649 19.0678 7.14619C19.0678 8.97043 18.2278 8.84316 17.1842 8.84316H3.98178C2.93814 8.84316 2.09814 8.97043 2.09814 7.14619Z" stroke="#292D32" strokeWidth="1.27273"/>
      <path d="M8.68262 12.3652V15.3774" stroke="#292D32" strokeWidth="1.27273" strokeLinecap="round"/>
      <path d="M12.5854 12.3652V15.3774" stroke="#292D32" strokeWidth="1.27273" strokeLinecap="round"/>
      <path d="M3.37109 8.9707L4.56746 16.3016C4.83897 17.9477 5.49231 19.1525 7.91897 19.1525H13.0353C15.6741 19.1525 16.0644 17.9986 16.3699 16.4034L17.7953 8.9707" stroke="#292D32" strokeWidth="1.27273" strokeLinecap="round"/>
    </svg>
  );
};

export default CartBagIcon;