import React from "react";

interface IProps {
  type: "button" | "submit" | "reset",
  name?: string,
  children?: React.ReactNode,
  size?: "large" | "small",
  onClick?: () => void,
  className?: string,
  sx?: React.CSSProperties,
  id?: string,
  disabled?: boolean
}

export const Button = ({ type, name, children, className, onClick, sx, id, disabled = false }: IProps) => {
  return (
    <div className={className ? className + " button" : "button"} style={sx}>
      <button name={name} onClick={onClick} type={type} id={id} disabled={disabled}>{children}</button>
    </div>
  );
};