import React from "react";

import { SearchIcon } from "../../assets/images";
import { handleKeyDown } from "../../utils/helper";

interface SearchBarProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  maxLength?: number
}

const SearchBar: React.FC<SearchBarProps> = ({ value, onChange, placeholder, maxLength }) => {
  return (
    <div className="search-bar">
      <span className="search-icon"><SearchIcon /></span>
      <input onKeyDown={handleKeyDown} type="text" value={value} onChange={onChange} placeholder={placeholder} maxLength={maxLength} />
    </div>
  );
};

export default SearchBar;