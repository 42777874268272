import React from "react";

import { NoDataFoundIcon, NoDataWidget } from "../../assets/images/icons";

interface IProps {
  text?: string;
  widget?: boolean
}

const NoDataFound = ({ text, widget = false }: IProps) => {
  return (
    <div className="no-data-wrapper w-100">
      {widget ?
        <div className="no-data-widget">
          <NoDataWidget />
        </div> :
        <div className="no-data-icon">
          <NoDataFoundIcon />
        </div>}
      <p className="caption">{text || "No data found."}</p>
    </div>
  );
};

export default NoDataFound;