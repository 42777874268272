import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

import axiosInstance from "../axios";

export const getExpandedTableData = createAsyncThunk("expandedTable/list", async (args: { payload?: any, path: string }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(args.path);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});