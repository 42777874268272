import React from "react";

const VerifiedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
      <g clipPath="url(#clip0_2305_133257)">
        <path d="M40 0C17.9375 0 0 17.9375 0 40C0 62.0625 17.9375 80 40 80C62.0625 80 80 62.0625 80 40C80 17.9375 62.0625 0 40 0ZM40 75C20.7031 75 5 59.2969 5 40C5 20.7031 20.7031 5 40 5C59.2969 5 75 20.7031 75 40C75 59.2969 59.2969 75 40 75ZM59.3438 26.5156C60.3125 27.4844 60.3125 29.0781 59.3438 30.0469L35.9062 53.4844C35.4219 53.9687 34.7812 54.2188 34.1406 54.2188C33.5 54.2188 32.8594 53.9687 32.375 53.4844L20.6562 41.7656C19.6875 40.7969 19.6875 39.2031 20.6562 38.2344C21.625 37.2656 23.2188 37.2656 24.1875 38.2344L34.1406 48.1875L55.8125 26.5156C56.7812 25.5312 58.375 25.5312 59.3438 26.5156Z" fill="url(#paint0_linear_2305_133257)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_2305_133257" x1="-38.7337" y1="38.7337" x2="38.7337" y2="116.201" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A1FF8B" />
          <stop offset="1" stopColor="#3F93FF" />
        </linearGradient>
        <clipPath id="clip0_2305_133257">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VerifiedIcon;