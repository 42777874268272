import React, { lazy, Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Spin } from "antd";

import ConnectionDetails from "../pages/connections/connectionDetails";
import PrivateRoute from "./PrivateRoute";
import Layout from "../pages/layout";
import AuthLayout from "../pages/auth/authLayout";
import LayoutPage from "../pages/landingPage/Layout";
import ComingSoon from "../pages/comingSoon";
import CreateRollForm from "../pages/accountSettings/createRollForm";
import OrdersPage from "../pages/accountSettings/orders";
import ProductSetting from "../pages/accountSettings/productSetting";
import ConnectionSettings from "../pages/accountSettings/connectionSettings";
import NotificationSettings from "../pages/accountSettings/notificationSettings";
import Subscription from "../pages/subscription";
import DownloadApp from "../downloadApp";
// import FreePlansLayout from "../pages/FreePlansLayout";
// import GetPremiumComponent from "../pages/dashboard/components/getPremium";

// const AuthLayout = lazy(() => import("../pages/auth/authLayout"));
// const Layout = lazy(() => import("../pages/layout"));
const PlaceOrder = lazy(() => import("../pages/placeOrder"));
const Login = lazy(() => import("../pages/auth/login"));
const Products = lazy(() => import("../pages/products"));
const AddProduct = lazy(() => import("../pages/products/AddProduct"));
const Connection = lazy(() => import("../pages/connections"));
const Orders = lazy(() => import("../pages/orders"));
const OrderDetails = lazy(() => import("../pages/orders/orderDetails"));
const SettingsPage = lazy(() => import("../pages/settings"));
const LandingPage = lazy(() => import("../pages/landingPage"));
const ProductDetails = lazy(() => import("../pages/placeOrder/productDetails"));
const Chat = lazy(() => import("../pages/chat"));
const DiscountAndOffers = lazy(() => import("../pages/discountAndOffers"));
const PrivacyPolicy = lazy(() => import("../pages/privacyPolicy"));
// const LayoutPage = lazy(() => import("../pages/landingPage/Layout"));
const AboutPage = lazy(() => import("../pages/about"));
const ContactPage = lazy(() => import("../pages/contact"));
const ProfileSetting = lazy(() => import("../pages/settings/profileSetting"));
const SpecialPriceListPage = lazy(() => import("../pages/specialPriceList"));
const ProductsPriceList = lazy(() => import("../pages/specialPriceList/productsPriceList"));
// const ConnectionDetails = lazy(() => import("../pages/connections/connectionDetails"));
const Disclaimer = lazy(() => import("../pages/landingPage/disclaimer"));
const TearmsAndConditions = lazy(() => import("../pages/landingPage/tearmsAndCondition"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const OrdersData = lazy(() => import("../pages/orders/ordersData"));
const Vendors = lazy(() => import("../pages/vendors"));
const ErrorPage = lazy(() => import("../pages/ErrorPage"));
const SomethingWrong = lazy(() => import("../pages/ErrorPage/SomethingWrong"));
const Page404 = lazy(() => import("../pages/ErrorPage/404"));
const EditProduct = lazy(() => import("../pages/products/editProduct"));
const GetHelp = lazy(() => import("../pages/getHelp"));
const AccountSetting = lazy(() => import("../pages/accountSettings"));

const Routing = () => {
  const Loader = () => (
    <div className="page-loader">
      <Spin />
    </div>
  );

  return (
    <Router>
      <Routes>
        {/* Protected Routes */}
        <Route element={<PrivateRoute />}>
          <Route element={<Layout />}>
            <Route path="/products" element={<Suspense fallback={<Loader />}><Products /></Suspense>} />
            <Route path="/products/:id" element={<Suspense fallback={<Loader />}><EditProduct /></Suspense>} />
            <Route path="/products/add" element={<Suspense fallback={<Loader />}><AddProduct /></Suspense>} />
            <Route path="/placeorder" element={<Suspense fallback={<Loader />}><PlaceOrder /></Suspense>} />
            <Route path="/placeorder/:id" element={<Suspense fallback={<Loader />}><ProductDetails /></Suspense>} />
            <Route path="/vendors" element={<Suspense fallback={<Loader />}><Vendors /></Suspense>} />
            <Route path="/orders" element={<Suspense fallback={<Loader />}><Orders /></Suspense>}>
              <Route path="/orders" element={<Suspense fallback={<Loader />}><OrdersData /></Suspense>} />
              <Route path="/orders/customer-orders" element={<Suspense fallback={<Loader />}><OrdersData /></Suspense>} />
              <Route path="/orders/my-orders" element={<Suspense fallback={<Loader />}><OrdersData /></Suspense>} />
            </Route>
            <Route path="/orders/:orderType/:id" element={<Suspense fallback={<Loader />}><OrderDetails /></Suspense>} />
            <Route path="/settings" element={<Suspense fallback={<Loader />}><AccountSetting /></Suspense>}>
              <Route path="/settings/:id/*" element={<ProfileSetting />} />
              {/* <Route path="/settings/user-and-permission" element={<UserRollPage />} /> */}
              {/* <Route path="/settings/:id" element={<UserRollPage />} /> */}
              {/* <Route path="/settings/:id/*" element={<UserRollPage />} /> */}
              <Route path="/settings/:id/createUser" element={<CreateRollForm />} />
              <Route path="/settings/:id/profile" element={<ProfileSetting />} />
              <Route path="/settings/:id/orders" element={<OrdersPage />} />
              <Route path="/settings/:id/products" element={<ProductSetting />} />
              <Route path="/settings/:id/connections" element={<ConnectionSettings />} />
              <Route path="/settings/:id/subscribe" element={<Subscription />} />
              <Route path="/settings/:id/notification" element={<NotificationSettings />} />
              <Route path="/settings/:id/company-details" element={<SettingsPage />} />
              <Route path="/settings/:id/user/:userId" element={<CreateRollForm />} />
              <Route path="/settings/:id/gethelp" element={<GetHelp />} />
            </Route>
            {/* <Route path="/profileSetting/:id" element={<Suspense fallback={<Loader />}><ProfileSetting /></Suspense>} /> */}
            <Route path="/special-price-list" element={<Suspense fallback={<Loader />}><SpecialPriceListPage /></Suspense>}>
              <Route path="/special-price-list/:id" element={<Suspense fallback={<Loader />}><ProductsPriceList /></Suspense>} />
            </Route>
            <Route path="/" element={<Suspense fallback={<Loader />}><Dashboard /></Suspense>}>
              <Route path="*" element={<Suspense fallback={<Loader />}><Dashboard /></Suspense>} />
              <Route path="/dashboard" element={<Suspense fallback={<Loader />}><Dashboard /></Suspense>} />
              <Route path="/dashboard/:id" element={<Suspense fallback={<Loader />}><ProductsPriceList /></Suspense>} />
            </Route>

            {/* <Route path="/placeorder/:id" element={<PlaceOrder />} /> */}
            <Route path="/connection" element={<Suspense fallback={<Loader />}><Connection /></Suspense>}>
              <Route path="/connection/:id" element={<Suspense fallback={<Loader />}><ConnectionDetails /></Suspense>} />
            </Route>
            <Route path="/chat" element={<Suspense fallback={<Loader />}><Chat /></Suspense>} />
            {/* <Route path="/vendors" element={<Vendors />} /> */}
            <Route path="/discount&offers" element={<Suspense fallback={<Loader />}><DiscountAndOffers /></Suspense>} />
          </Route>
        </Route>
        {/* Public Routes */}
        <Route path="/get-app" element={<Suspense fallback={<Loader />}><DownloadApp /></Suspense>} />
        <Route path="/coming-soon" element={<Suspense fallback={<Loader />}><ComingSoon /></Suspense>} />
        <Route path="/500" element={<Suspense fallback={<Loader />}><ErrorPage /></Suspense>} />
        <Route path="/error" element={<Suspense fallback={<Loader />}><SomethingWrong /></Suspense>} />
        <Route path="/404" element={<Suspense fallback={<Loader />}><Page404 /></Suspense>} />
        <Route path="/gethelp" element={<Suspense fallback={<Loader />}><GetHelp /></Suspense>} />
        <Route path="/" element={<AuthLayout />}>
          <Route path="/login" element={<Suspense fallback={<Loader />}><Login /></Suspense>} />
        </Route>
        {/* <Route path="/subscribe" element={<FreePlansLayout />}>
          <Route path="/subscribe" element={<Suspense fallback={<Loader />}><GetPremiumComponent /></Suspense>} />
          <Route path="/subscribe/*" element={<Suspense fallback={<Loader />}><GetPremiumComponent /></Suspense>} />
          <Route path="/subscribe/plans" element={<Suspense fallback={<Loader />}><Subscription /></Suspense>} />
        </Route> */}
        <Route path="/home" element={<LayoutPage />}>
          <Route path="/home" element={<Suspense fallback={<Loader />}><LandingPage /></Suspense>} />
          <Route path="/home/about" element={<Suspense fallback={<Loader />}><AboutPage /></Suspense>} />
          <Route path="/home/contact" element={<Suspense fallback={<Loader />}><ContactPage /></Suspense>} />
          <Route path="/home/privacyPolicy" element={<Suspense fallback={<Loader />}><PrivacyPolicy /></Suspense>} />
          <Route path="/home/tearmsandcondition" element={<Suspense fallback={<Loader />}><TearmsAndConditions /></Suspense>} />
          <Route path="/home/disclaimer" element={<Suspense fallback={<Loader />}><Disclaimer /></Suspense>} />
        </Route>
      </Routes>
    </Router>
  );
};

export default Routing;