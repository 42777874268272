import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

import endPoins from "./endPoints.json";
import axiosInstance from "../axios";

export const getNewOrders = createAsyncThunk("orders/new", async (args, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.getNewOrders);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const orderSeen = createAsyncThunk(endPoins.orderSeen, async (args, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.orderSeen);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getCompanyOrderData = createAsyncThunk(endPoins.getCompanyOrderDetails, async (args: { customerId: number }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.getCompanyOrderDetails, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getOrdersData = createAsyncThunk("orders/get", async (args: {
  type: number, //// 1 = Customer's Orders, 0 = my orders
  search: string,
  startDate: string,
  endDate: string,
  limit: number
  offset: number,
}, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.getOrdersData, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getOrderItemsByOrder = createAsyncThunk("orders/productsByCustomer", async (args: number, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${endPoins.getOrderItemsByOrder}/${args}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const acceptOrRejectOrder = createAsyncThunk("orders/acceptOrRejectOrder", async (args: {
  orderId: number,
  action: string
}, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.patch(endPoins.acceptOrRejectOrder, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getOrderDetails = createAsyncThunk("orders/getOrderDetails", async (orderId: number, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${endPoins.getOrderDetails}/${orderId}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const updateOrderByViewer = createAsyncThunk("orders/updateOrder", async (args: { orderId: number }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.patch(endPoins.updateOrderByViewer, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const updateStatusByOrderId = createAsyncThunk("status/update", async (args: { orderId: number, action: string, cancelReason?: string }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.patch(endPoins.updateStatusByOrderId, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const updateStatusByorderDispatchId = createAsyncThunk("status/updateDispatchStatus", async (args: { orderDispatchId: number, action: string, cancelReason?: string }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.patch(endPoins.updateStatusByOrderDispatchId, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

interface dispatchDetailsType {
  orderId: number,
  transporterName?: string,
  trackingId?: string,
  pincode?: string,
  addressLine?: string,
  dispatchItems?: { orderItemId: number, quantity: number }[] | undefined,
}

export const orderDispatch = createAsyncThunk("order/dispatch", async (
  args: {
    dispatchDetails: dispatchDetailsType,
    transportationAttachments?: File[]
  }, { rejectWithValue }) => {
  try {

    const formData = new FormData();
    args?.transportationAttachments?.forEach((file: string | Blob) => {
      formData.append("transportationAttachments", file);
    });
    formData.append("dispatchDetails", JSON.stringify(args.dispatchDetails));

    const response = await axiosInstance.post(endPoins.orderDispatch, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const updateTransportation = createAsyncThunk("order/addTransportation", async (
  args: {
    transportationDetails: dispatchDetailsType,
    transportationAttachments?: File[]
  }, { rejectWithValue }) => {
  try {

    const formData = new FormData();
    args?.transportationAttachments?.forEach((file: string | Blob) => {
      formData.append("transportationAttachments", file);
    });
    formData.append("transportationDetails", JSON.stringify(args.transportationDetails));

    const response = await axiosInstance.post(endPoins.updateTransportation, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getDispatchDetails = createAsyncThunk(endPoins.getDispatchDetails, async (orderId: number, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${endPoins.getDispatchDetails}/${orderId}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getDispatchesList = createAsyncThunk(endPoins.getDispatchesList, async (orderId: number, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${endPoins.getDispatchesList}/${orderId}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const fetchOrderCancleReasons = createAsyncThunk("orders/fetchOrderCancleReasons", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(endPoins.fetchOrderCancleReasons);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});