import React from "react";

const PhoneOutlinedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M1.61242 3.21697L3.47594 1.35521C3.70341 1.12773 4.00787 1 4.32983 1C4.65179 1 4.95625 1.12598 5.18373 1.35521L7.19073 3.35871C7.4182 3.58618 7.54593 3.89239 7.54593 4.21435C7.54593 4.53806 7.41995 4.84077 7.19073 5.06999L5.62292 6.63954C5.98008 7.47172 6.49325 8.22782 7.13473 8.86702C7.7769 9.51269 8.52756 10.0219 9.36045 10.3823L10.9283 8.81277C11.1557 8.5853 11.4602 8.45757 11.7822 8.45757C11.941 8.45698 12.0983 8.4881 12.2449 8.54909C12.3915 8.61008 12.5245 8.69973 12.636 8.81277L14.6448 10.8163C14.8723 11.0437 15 11.35 15 11.6719C15 11.9956 14.874 12.2983 14.6448 12.5276L12.783 14.3893C12.3946 14.7778 11.8591 15 11.3097 15C11.196 15 11.0857 14.9913 10.9738 14.972C8.65704 14.5906 6.35783 13.357 4.50131 11.5022C2.64654 9.64392 1.4147 7.34471 1.02974 5.02625C0.919508 4.36833 1.13998 3.69116 1.61242 3.21697ZM2.27034 4.81802C2.61155 6.88102 3.72091 8.93876 5.39195 10.6098C7.06299 12.2808 9.11898 13.3902 11.182 13.7314C11.4409 13.7752 11.7069 13.6877 11.8959 13.5004L13.7244 11.6719L11.7857 9.73141L9.68941 11.8311L9.67367 11.8469L9.29571 11.7069C8.14973 11.2856 7.10907 10.6201 6.24586 9.75657C5.38265 8.89306 4.71752 7.85217 4.29659 6.70604L4.1566 6.32808L6.27034 4.2161L4.33158 2.27559L2.50306 4.10411C2.31408 4.29309 2.22659 4.55906 2.27034 4.81802Z" fill="#94A2B8" />
    </svg>
  );
};

export default PhoneOutlinedIcon;