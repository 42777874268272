import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isTablet } from "react-device-detect";

import { detectDevice } from "../utils/helper";
// import { SubscriptionModules } from "../utils/constant";
// import { getSubscriptionPayloadData } from "../redux/services/subscription";
// import { useAppDispatch } from "../hooks/useAppDispatch";
// import { useAppSelector } from "../hooks/useAppSelector";
// import { logoutDevice } from "../redux/services/common";

const PrivateRoute = () => {
  const token = localStorage.getItem("token");
  // const dispatch = useAppDispatch();
  // const { subscriptionData } = useAppSelector((state) => state.subscription);
  // const deviceRestriction = subscriptionData?.data?.[0]?.isActive;
  // const fcmToken = localStorage.getItem("fcmToken");
  // const { userDetails } = useAppSelector((state) => state.common);

  useEffect(() => {
    if (detectDevice()?.type === "Mobile") {
      if (detectDevice()?.os === "iOS") { ///iPad|iPhone|iPod/
        window.history.back();
        window.location.href = "https://apps.apple.com/in/app/biizline/id6599846985";
      } else {
        window.history.back();
        window.location.href = "https://biizline.com/coming-soon";
      }
    } else if (isTablet) {
      window.location.href = "https://biizline.com/coming-soon";
    }
  }, [window.location, detectDevice()?.type]);

  // useEffect(() => {
  //   if (detectDevice()?.type === "Desktop") {
  //     dispatch(getSubscriptionPayloadData({ module: SubscriptionModules.PLATFORM }));
  //   }
  // }, [detectDevice()?.type, dispatch]);

  // useEffect(() => {
  //   if (!deviceRestriction) {
  //     localStorage.clear();
  //     const payload = {
  //       token: fcmToken,
  //       from: "Web",
  //     };
  //     dispatch(logoutDevice({ id: 0, payload }));
  //   }
  // }, [deviceRestriction]);

  return token && detectDevice()?.type === "Desktop" ? <Outlet /> : <Navigate to="/home" />;
};

export default PrivateRoute;