import { createSlice } from "@reduxjs/toolkit";

import { getAllSizesWithSearch, getCartDataByVendorID, getFavouriteProductList, getProductListByVendorId, mostOrderedProducts, placeOrder, recentOrders } from "../services/placeOrder";
import { OrderDetailsProps } from "../../types/ordersTypes";
import { ProductSizes } from "../../types/productsType";
import { VendorListDataProps, vendorProductProps } from "../../types/vendorType";

interface CartProps {
  cartId: number,
  cartItems: {
    cartItemId: number,
    quantity: number,
    orderUnitConvertionQuantity: number,
    orderUnit: string,
    orderUnitConvertionUnit: string,
    productSizeUnit: string,
    pricePerUnit: number,
    discount: number,
    subtotal: number,
    product: {
      productId: number,
      productName: string,
      isActive: boolean,
      productImage: {
        thumbnailURL: string
      }
    },
    productSize: {
      productSizeId: number,
      productSize: string,
      productSizeUnit: string,
      productSizeOrderUnit: string,
      alternateUnitPrimaryUnit?: string;
      pricePerUnit?: number | null,
      moq: number,
      productBulkRates: {
        isRateInRupees: boolean,
        placePosition: number,
        productBulkRateId: number,
        productQuantity: number,
        productRate: number
      }[]
    }
  }[]
}

const initialState: {
  vendorsProductList: {
    isLoading: boolean,
    isSuccess: boolean,
    data: VendorListDataProps[] | null,
    message: string
  },
  cart: {
    isLoading: boolean,
    isSuccess: boolean,
    data: CartProps | null,
    message: string
  },
  recentOrdersData: {
    isLoading: boolean,
    isSuccess: boolean,
    data: OrderDetailsProps[] | null,
    message: string
  },
  getAllSizes: {
    isLoading: boolean,
    isSuccess: boolean,
    data: ProductSizes[] | null,
    message: string
  },
  mostOrderedProductsData: {
    data: vendorProductProps[] | null,
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
  },
  favouriteProductList: {
    data: vendorProductProps[] | null,
    isLoading: boolean,
    isSuccess: boolean,
    message: string,
  },
  placeOrderData: {
    isSuccess: boolean
  }
} = {
  vendorsProductList: {
    isLoading: false,
    isSuccess: false,
    data: [],
    message: "",
  },
  cart: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
  recentOrdersData: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
  getAllSizes: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
  mostOrderedProductsData: {
    data: null,
    isLoading: false,
    isSuccess: false,
    message: "",
  },
  favouriteProductList: {
    data: null,
    isLoading: false,
    isSuccess: false,
    message: "",
  },
  placeOrderData: {
    isSuccess: false,
  },
};

const placeorderSlice = createSlice({
  name: "placeorder",
  initialState,
  reducers: {
    clearCart: (state) => {
      state.cart = initialState.cart;
    },
    updateMostOrderProductsFilterd: (state, { payload }) => {
      state.mostOrderedProductsData.data = payload;
    },
    updateFavouriteProductList: (state, { payload }) => {
      state.favouriteProductList.data = payload;
    },
    updatePlaceOrderStatus: (state, { payload }) => {
      state.placeOrderData.isSuccess = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductListByVendorId.pending, (state) => {
      state.vendorsProductList.data = null;
      state.vendorsProductList.isLoading = true;
    });
    builder.addCase(getProductListByVendorId.fulfilled, (state, { payload }: any) => {
      state.vendorsProductList.isSuccess = true;
      state.vendorsProductList.isLoading = false;
      state.vendorsProductList.data = payload?.data;
    });
    builder.addCase(getProductListByVendorId.rejected, (state, { payload }: any) => {
      state.vendorsProductList.data = payload;
      state.vendorsProductList.isLoading = false;
      state.vendorsProductList.isSuccess = false;
    });

    builder.addCase(getCartDataByVendorID.pending, (state) => {
      // state.cart.data = null;
      state.cart.isLoading = true;
      state.cart.isSuccess = false;
    });
    builder.addCase(getCartDataByVendorID.fulfilled, (state, { payload }: any) => {
      state.cart.isSuccess = true;
      state.cart.isLoading = false;
      state.cart.data = payload?.data;
    });
    builder.addCase(getCartDataByVendorID.rejected, (state, { payload }: any) => {
      state.cart.data = payload;
      state.cart.isLoading = false;
      state.cart.isSuccess = false;
    });

    // recentOrders
    builder.addCase(recentOrders.pending, (state) => {
      state.recentOrdersData.isLoading = true;
    });
    builder.addCase(recentOrders.fulfilled, (state, { payload }: any) => {
      state.recentOrdersData.isSuccess = true;
      state.recentOrdersData.isLoading = false;
      state.recentOrdersData.data = payload?.data;
    });
    builder.addCase(recentOrders.rejected, (state, { payload }: any) => {
      state.recentOrdersData.data = payload;
      state.recentOrdersData.isLoading = false;
      state.recentOrdersData.isSuccess = false;
    });

    // getAllSizesWithSearch
    builder.addCase(getAllSizesWithSearch.pending, (state) => {
      state.getAllSizes.isLoading = true;
    });
    builder.addCase(getAllSizesWithSearch.fulfilled, (state, { payload }: any) => {
      state.getAllSizes.isSuccess = true;
      state.getAllSizes.isLoading = false;
      state.getAllSizes.data = payload;
    });
    builder.addCase(getAllSizesWithSearch.rejected, (state, { payload }: any) => {
      state.getAllSizes.data = payload;
      state.getAllSizes.isLoading = false;
      state.getAllSizes.isSuccess = false;
    });

    // mostOrderedProducts
    builder.addCase(mostOrderedProducts.pending, (state) => {
      state.mostOrderedProductsData.isLoading = true;
      state.mostOrderedProductsData.isSuccess = false;
    });
    builder.addCase(mostOrderedProducts.fulfilled, (state, { payload }: any) => {
      state.mostOrderedProductsData.isLoading = false;
      state.mostOrderedProductsData.isSuccess = true;
      state.mostOrderedProductsData.data = payload?.data;
      state.mostOrderedProductsData.message = payload?.message;
    });
    builder.addCase(mostOrderedProducts.rejected, (state, { payload }: any) => {
      state.mostOrderedProductsData.isLoading = false;
      state.mostOrderedProductsData.isSuccess = false;
      state.mostOrderedProductsData.message = payload?.message;
    });

    // get favourite products Data
    builder.addCase(getFavouriteProductList.pending, (state) => {
      state.favouriteProductList.isLoading = true;
      state.favouriteProductList.isSuccess = false;
    });
    builder.addCase(getFavouriteProductList.fulfilled, (state, { payload }: any) => {
      state.favouriteProductList.isLoading = false;
      state.favouriteProductList.isSuccess = true;
      state.favouriteProductList.data = payload?.data;
      state.favouriteProductList.message = payload?.message;
    });
    builder.addCase(getFavouriteProductList.rejected, (state, { payload }: any) => {
      state.favouriteProductList.isLoading = false;
      state.favouriteProductList.isSuccess = false;
      state.favouriteProductList.message = payload?.message;
    });

    // place order
    builder.addCase(placeOrder.pending, (state) => {
      state.placeOrderData.isSuccess = false;
    });
    builder.addCase(placeOrder.fulfilled, (state, { payload }: any) => {
      state.placeOrderData.isSuccess = true;
    });
    builder.addCase(placeOrder.rejected, (state, { payload }: any) => {
      state.placeOrderData.isSuccess = false;
    });
  },
});

export const { clearCart, updateMostOrderProductsFilterd, updateFavouriteProductList, updatePlaceOrderStatus } = placeorderSlice.actions;
export default placeorderSlice.reducer;