import React, { useState } from "react";

import WpIcon from "../../assets/images/wpIcon";
import VerifyOtpModal from "../../pages/settings/verifyOtpModal";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { getProfileDetails, updateMobile } from "../../redux/services/settings";

interface IPhoneNumberInputProps {
  required?: boolean;
}

export const PhoneNumberInput = ({ required }: IPhoneNumberInputProps) => {
  const [value, setValue] = useState<any>({});
  const [verify, setVerify] = useState(false);
  const dispatch = useAppDispatch();

  const { userProfile } = useAppSelector((state) => state.setting);

  const handleUpdateNumber = () => {
    const payload = {
      userId: userProfile.data?.userId || 0,
      phoneNumber: value?.phone_number,
    };
    dispatch(updateMobile(payload)).then((result) => {
      if (result.payload.status === 200) {
        localStorage.setItem("token", result?.payload?.data?.token);
        dispatch(getProfileDetails());
      }
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setValue((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleVerify = () => {
    setVerify(true);
  };

  return (
    <div className="phone-number-input-wrapper">
      <label htmlFor="" className="phone-number-label">
        <div className="label-text flex alignStart">Phone Number {required ? <span className="require-icon"> *</span> : ""}</div>
        <div className="input-container flex alignCenter justifyBetween rounded-8 gap-2">
          <span className="whatsapp-icon"><WpIcon /></span>
          <input type="text" name="phone_number" className="phone-number-input" placeholder="Enter WhatsApp number" onChange={handleChange} />
          {value?.phone_number?.length === 10 ? <div className="verify-button">
            <button className="verify" type="button" onClick={handleVerify}>Verify</button>
          </div> : ""}
        </div>
      </label>
      {verify ? <VerifyOtpModal close={() => setVerify(false)} open={verify} phoneNumber={value?.phone_number} handleUpdate={handleUpdateNumber} /> : ""}
    </div>
  );
};