import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

const LayoutPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard", { replace: true });
    }
  }, [navigate]);

  return (
    <div className="landing-page-wrapper">
      <div className="landing-page-content">
        <Header />
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default LayoutPage;