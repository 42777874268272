import React, { useState } from "react";
import { Switch } from "antd";

import UnBlockConnectionPopup from "./unBlockConnectionPopup";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { BlockUnblockRemoveConnection } from "../../redux/services/vendor";
import { getBlockedConnectionsList, getNotificationModulesList, getProductsManagementData, updateNotificationManagementData, updateProductManagementData } from "../../redux/services/accountSetting";
import { ALTER_TEXT, COMMON_STRINGS, ERROR_STRINGS, NO_DATA_FOUND_STRINGS } from "../../utils/constant";
import { UserIcon } from "../../assets/images/icons";
import NoDataFound from "../../components/NoDataFound";
import useMessageHook from "../../hooks/useMessageHook";
import SettingsCommonTableSkeleton from "../../components/skeletons/settingsCommonTableSkeleton";

interface ICommonTableProps {
  switchButton?: boolean;
  title?: string;
  data?: any[];
  type?: string;
  isLoading?: boolean;
}

const CommonTable = ({ switchButton = true, title, data, type, isLoading }: ICommonTableProps) => {
  const [toggle, setToggle] = useState(false);
  const [connectionId, setConnectionId] = useState(0);
  const { contextHolder, showMessage } = useMessageHook();

  const dispatch = useAppDispatch();

  const handleHover = (e: any, id: number) => {
    const target = e.currentTarget; // Use currentTarget for reliability
    setConnectionId(id);
    if (target) {
      target.innerHTML = COMMON_STRINGS.UNBLOCK; // Modify directly
    }
  };
  const handleHoverLeave = (e: any) => {
    const target = e.currentTarget; // Use currentTarget for reliability
    if (target) {
      target.innerHTML = COMMON_STRINGS.BLOCKED; // Modify directly
    }
  };

  const handleUnBlockConnection = async () => {
    const payload = {
      connectionId: connectionId,
      action: "Unblock",
    };
    await dispatch(BlockUnblockRemoveConnection(payload)).then((result) => {
      if (result?.payload?.status === 200) {
        dispatch(getBlockedConnectionsList());
      }
    }).catch((error) => console.error(error));
  };

  const handleStatusChange = async (e: boolean, id: number) => {
    if (type === "notification") {
      await dispatch(updateNotificationManagementData({
        notificationSettingId: id,
        isActive: e,
      })).then((result) => {
        if (result?.payload?.status === 200) {
          showMessage("success", result?.payload?.message);
        } else {
          dispatch(getNotificationModulesList());
        }
      }).catch((error) => {
        dispatch(getNotificationModulesList());
        showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
      });
    } else {
      await dispatch(updateProductManagementData({
        productAccountSettingId: id,
        isActive: e,
      })).then((result) => {
        if (result?.payload?.status === 200) {
          showMessage("success", result?.payload?.message);
        } else {
          dispatch(getProductsManagementData());
        }
      }).catch((error) => {
        dispatch(getProductsManagementData());
        showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
      });
    }
  };

  return (
    <div className="table rounded-12">
      {contextHolder}
      <div className="table-header">
        <p className="table-heading">{title}</p>
      </div>
      <div className="table-body">

        {data?.length ? data?.map((item, index) => {
          return (
            <div className="table-row flex alignCenter justifyBetween" key={index}>
              <div className="flex gap-2">
                {item?.name ? <div className="img-container flex alignCenter justifyCenter">
                  {item?.profileThumbnailImg ? <img src={item?.profileThumbnailImg} alt={ALTER_TEXT.PROFILE_IMAGE} /> : <UserIcon />}
                </div> : ""}
                <div className="flex direction-column">
                  <p className="table-data">{item?.name || item?.moduleName}</p>
                  {item?.createdBy ? <p className="name-text">{item?.createdBy?.firstName + " " + item?.createdBy?.lastName}</p> : ""}
                </div>
              </div>
              <div className="table-data">
                {switchButton ? <Switch
                  onChange={(e) => handleStatusChange(e, item?.productAccountSettingId || item?.notificationSettingId)}
                  defaultChecked={item?.isActive}
                  className="switch-style text-end"
                /> : <button className="block-button gap-2 flex alignCenter" onMouseEnter={(e: any) => handleHover(e, item?.connectionId)} onMouseLeave={handleHoverLeave} onClick={() => setToggle(true)}>{COMMON_STRINGS.BLOCKED}</button>}
              </div>
            </div>
          );
        }) : isLoading ? <>
          <SettingsCommonTableSkeleton />
          <SettingsCommonTableSkeleton />
          <SettingsCommonTableSkeleton />
          <SettingsCommonTableSkeleton />
          <SettingsCommonTableSkeleton />
        </> : <NoDataFound text={NO_DATA_FOUND_STRINGS.NO_RECORD_DATA} />}
      </div>
      <UnBlockConnectionPopup onSubmit={handleUnBlockConnection} setToggle={setToggle} toggle={toggle} />
    </div>
  );
};

export default CommonTable;