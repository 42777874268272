import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Add your base URL here
  headers: {
    "Content-Type": "application/json",
    "x-forword-from": "Web",
  },
});

// Variable to hold the promise while refreshing the token
let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string = localStorage.getItem("token") ?? "") => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Add authorization token or other headers here
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Function to refresh the token
const refreshToken = async () => {
  const fcm_token = localStorage.getItem("fcm_token");
  try {
    const resp = await axiosInstance.post("/fetchRefreshToken", {
      FCMtoken: fcm_token,
      from: "Web",
    });
    return resp.data?.data?.token;
  } catch (e) {
    // Handle token refresh failure (logout, clear storage)
    localStorage.clear();
    window.location.reload();
    return Promise.reject(e);
  }
};

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (originalRequest?.url === "/fetchRefreshToken" && error.response.status === 401 && !originalRequest._retry) {
      localStorage.clear();
      window.location.reload();
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        refreshToken()
          .then((newToken) => {
            // Update localStorage and headers with the new token
            if (newToken) {
              localStorage.setItem("token", newToken);
              axiosInstance.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${newToken}`;
              originalRequest.headers["Authorization"] = "Bearer " + newToken;
            }

            processQueue(null, newToken);
            resolve(axiosInstance(originalRequest));
          })
          .catch((err) => {
            processQueue(err, "");
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    if (
      error.response.status === 504 ||
      error.response.status === 500 ||
      error?.code === "ERR_NETWORK"
    ) {
      // Handle server/network errors gracefully
      window.location.replace("/500");
    } else if (error.response.status === 404) {
      window.location.replace("/404");
    } else if (error.response.status !== 401 && error.response.status !== 400) {
      window.location.replace("/error");
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;