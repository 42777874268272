import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";

// Set up the S3 client using IAM credentials
const s3 = new S3Client({
  region: process.env.REACT_APP_AWS_REGION ?? "", // Replace with your bucket's region
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? "", // Replace with your Access Key ID
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? "", // Replace with your Secret Access Key
  },
});

// Function to convert ReadableStream to Blob
const streamToBlob = async (stream: ReadableStream): Promise<Blob> => {
  const reader = stream.getReader();
  const chunks: Uint8Array[] = [];
  
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    chunks.push(value);
  }
  
  return new Blob(chunks);
};

  
export async function getImage(bucketName: string, key: string) {
  const command = new GetObjectCommand({ Bucket: bucketName, Key: key });
  try {
    const data = await s3.send(command);

    // Check if the Body exists before attempting to convert it
    if (data.Body) {
    // Convert the ReadableStream to a Blob
      const blob = await streamToBlob(data.Body as ReadableStream);
      return blob;
    //   const url = URL.createObjectURL(blob);
    //   return url;
    } else {
      console.error("No content found in the response");
      return null;
    }
  } catch (error) {
    console.error("Error fetching image:", error);
    return null;
  }
}
