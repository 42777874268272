import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

import endPoins from "./endPoints.json";
import axiosInstance from "../axios";
import { createGroupPayloadType, editGroupPayloadType } from "../../types/productsType";

export const manageField = createAsyncThunk("product/managementFields", async (payload: {
  productId: number;
  search: string;
  limit: number;
  fieldType: number;
  offset: number
}, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.manageFields, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getProductFieldManageField = createAsyncThunk("product/fieldManagementFields", async (payload: {
  productId: number;
  search: string;
  limit: number;
  fieldType: number;
  offset: number
}, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.manageFields, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const addProduct = createAsyncThunk("product/add", async (product: { product: any, productData: object }, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    product.product.forEach((file: string | Blob) => {
      formData.append("productImages", file);
    });
    formData.append("productData", JSON.stringify(product.productData));

    const response = await axiosInstance.post(endPoins.addProduct, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getProductUnits = createAsyncThunk("product/getUnit", async (payload: { search: string }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.getUnit, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getListOfProducts = createAsyncThunk("product/getAll", async (payload: {
  search: string;
  groupId: number;
  vendorId: number;
  limit: number;
  offset: number;
}, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.getProductsList, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getListOfProductSizes = createAsyncThunk("product/getAllSizes", async (productId: number, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${endPoins.getProductSizes}/${productId}`);
    return response.data.data.productSizes;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getProductsGroups = createAsyncThunk("productGroup/getGroups", async (payload: { search: string, vendorId?: number }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.getProductGroups, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getPlaceOrderProductsGroups = createAsyncThunk("productGroup/getGroupsdata", async (payload: { search: string, vendorId?: number }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.getProductGroups, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getProductListInGroup = createAsyncThunk("productGroup/getProductsByGroup", async (data: any, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.getProductsByGroup, data);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getAllProductsForGroup = createAsyncThunk("product/getAllProducts", async (payload: {
  search: string;
  vendorId: number;
  limit: number;
  offset: number;
  forGroup: boolean;
}, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.getProductsList, payload);

    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const createGroup = createAsyncThunk("productGroup/createGroup", async (data: createGroupPayloadType, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.createGroup, data);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const editGroup = createAsyncThunk("productGroup/editGroup", async (data: editGroupPayloadType, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.editGroup, data);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

// get product by id

export const getProductDetails = createAsyncThunk("product/get", async (productId: number, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${endPoins.getProductDetails}/${productId}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

// Delete Group

export const deleteGroup = createAsyncThunk("productGroup/deleteGroup", async (productId: number, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.deleteGroup, {
      "productGroupIds": [productId],
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

// Update product

export const editProduct = createAsyncThunk("product/update", async (product: { id: number | null, product: any, productData: object }, { rejectWithValue }) => {
  try {

    const formData = new FormData();
    product.product.forEach((file: string | Blob) => {
      formData.append("productImages", file);
    });

    formData.append("productData", JSON.stringify(product.productData));

    const response = await axiosInstance.post(`${endPoins.updateProduct}/${product.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

// Delete Products

export const deleteProduct = createAsyncThunk("product/delete", async (productIds: number[], { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.deleteProduct, {
      "productIds": productIds,
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const deleteSizes = createAsyncThunk("sizes/delete", async (payload: { productSizeIds: number[] }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.deleteSizes, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const updateBulkStatusForSizes = createAsyncThunk("sizes/bulkStatus", async (payload: { productSizeIds: number[], isActive: boolean }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.bulkStatusForSizes, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const updateBulkStatusForProducts = createAsyncThunk("products/bulkStatus", async (payload: { productIds: number[], isActive: boolean }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.bulkStatusForProducts, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const checkExistingProduct = createAsyncThunk("product/checkExistingProduct", async (productName: string, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.checkExistingProduct, {
      "name": productName,
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const updateProductName = createAsyncThunk("product/updateProductName", async (payload: { productName: string, productId: number }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.patch(endPoins.updateProductName, {
      productId: payload.productId,
      productName: payload.productName,
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});


export const updateProductField = createAsyncThunk("product/updateProductData", async (payload: { productSizeId: number, data: any }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.patch(`${endPoins.updateProductField}/${payload.productSizeId}`, payload.data);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const updateProductSizeBulkRateField = createAsyncThunk("product/updateProductSizeBulkRate", async (payload: { productSizeId: number, data: any }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.patch(`${endPoins.updateProductSizeBulkRateField}/${payload.productSizeId}`, { bulkRates: payload.data });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

/// Product active inactive
export const activeInactiveProduct = createAsyncThunk("product/active", async (arg: {
  productIds: number[],
  isActive: boolean,
}, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.activeInactiveProduct, arg);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const customFieldSuggesion = createAsyncThunk("customField/suggesion", async (payload: { search: string, fieldType: number, limit: number }, { rejectWithValue }) => {
  const { fieldType } = payload;
  try {
    const response = await axiosInstance.post(endPoins.manageFieldSuggestion, payload);
    return { [fieldType]: response?.data };
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const productNameSuggestion = createAsyncThunk(endPoins.productNameSuggestion, async (productName: string, { rejectWithValue }) => {

  try {
    const response = await axiosInstance.post(endPoins.productNameSuggestion, { name: productName });
    return response?.data.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const productSearchWithGroup = createAsyncThunk(endPoins.productSearchWithGroup, async (arg: { search: string, vendorId: number, status: number }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.productSearchWithGroup, arg);
    return response?.data.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});