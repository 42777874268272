import React from "react";

const StatusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path d="M8.50008 13.3332C11.4456 13.3332 13.8334 10.9454 13.8334 7.99984C13.8334 5.05432 11.4456 2.6665 8.50008 2.6665C5.55456 2.6665 3.16675 5.05432 3.16675 7.99984C3.16675 10.9454 5.55456 13.3332 8.50008 13.3332Z" stroke="#D9D9D9" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.50008 9.59984C9.38374 9.59984 10.1001 8.88349 10.1001 7.99984C10.1001 7.11618 9.38374 6.39984 8.50008 6.39984C7.61643 6.39984 6.90008 7.11618 6.90008 7.99984C6.90008 8.88349 7.61643 9.59984 8.50008 9.59984Z" stroke="#D9D9D9" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default StatusIcon;