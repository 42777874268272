import { createSlice } from "@reduxjs/toolkit";

import { getPaymentDetailsSummary, getPlateformSubscriptionPayloadData, getSubscriptionPayloadData, getSubscriptionPlanDetails } from "../services/subscription";

interface IProps {
  planDetails: {
    isLoading: boolean,
    isSuccess: boolean,
    data: {
      features: {
        moduleName: string,
        Free: {
          subscriptionActivityDisplayText: string,
          isActive: boolean
        },
        Gold: {
          subscriptionActivityDisplayText: string,
          isActive: boolean
        },
        Premium: {
          subscriptionActivityDisplayText: string,
          isActive: boolean
        }
      }[],
      plans: {
        subscriptionId: number;
        name: string;
        price: string | number;
        disscountInPercentage: string | number;
        disscountInRuppes: string | number;
        image: string;
        isActive: boolean;
        supportiveText: string | null;
      }[]
    } | null,
    message: string,
  };
  planDetailsSummary: {
    isLoading: boolean;
    isSuccess: boolean;
    data: {
      planDetails: {
        planName: string;
        price: string;
        image: string;
        supportiveText: string;
        subscriptionId: number;
      }
      billingDitails: {
        subtotal: string;
        grandTotal: string;
        promocodeDiscount: string;
        subscriptionDiscount: string;
        otherDiscount: string;
        CGSTInRupees: string;
        CGSTInPercentage: string;
        SGSTInRupees: string;
        SGSTInPercentage: string;
        IGSTInRupees: string;
        IGSTInPercentage: string;
        needGSTInvoice: boolean;
      }
    } | null;
    message: string;
  };
  subscriptionData: {
    isLoading: boolean;
    isSuccess: boolean;
    message: string;
    data: any;
  };
  platformSubscriptionData: {
    isLoading: boolean;
    isSuccess: boolean;
    message: string;
    data: any;
  }
}

const initialState: IProps = {
  planDetails: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
  planDetailsSummary: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
  subscriptionData: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
  platformSubscriptionData: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    clearPaymentSummary: (state) => {
      state.planDetailsSummary = initialState.planDetailsSummary;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionPlanDetails.pending, (state) => {
      state.planDetails.isLoading = true;
      state.planDetails.isSuccess = false;
    });
    builder.addCase(getSubscriptionPlanDetails.fulfilled, (state, { payload }: any) => {
      state.planDetails.isSuccess = true;
      state.planDetails.isLoading = false;
      state.planDetails.data = payload?.data;
      state.planDetails.message = payload?.message;
    });
    builder.addCase(getSubscriptionPlanDetails.rejected, (state, { payload }: any) => {
      state.planDetails.data = payload;
      state.planDetails.isSuccess = false;
      state.planDetails.isLoading = false;
      state.planDetails.message = payload?.message;
    });

    builder.addCase(getPaymentDetailsSummary.pending, (state) => {
      state.planDetailsSummary.isLoading = true;
      state.planDetailsSummary.isSuccess = false;
    });
    builder.addCase(getPaymentDetailsSummary.fulfilled, (state, { payload }: any) => {
      state.planDetailsSummary.isSuccess = true;
      state.planDetailsSummary.isLoading = false;
      state.planDetailsSummary.data = payload?.data;
      state.planDetailsSummary.message = payload?.message;
    });
    builder.addCase(getPaymentDetailsSummary.rejected, (state, { payload }: any) => {
      state.planDetailsSummary.data = payload;
      state.planDetailsSummary.isSuccess = false;
      state.planDetailsSummary.isLoading = false;
      state.planDetailsSummary.message = payload?.message;
    });

    builder.addCase(getSubscriptionPayloadData.pending, (state) => {
      state.subscriptionData.isLoading = true;
      state.subscriptionData.isSuccess = false;
    });
    builder.addCase(getSubscriptionPayloadData.fulfilled, (state, { payload }: any) => {
      state.subscriptionData.isSuccess = true;
      state.subscriptionData.isLoading = false;
      state.subscriptionData.data = payload?.data;
      state.subscriptionData.message = payload?.message;
    });
    builder.addCase(getSubscriptionPayloadData.rejected, (state, { payload }: any) => {
      state.subscriptionData.data = payload;
      state.subscriptionData.isSuccess = false;
      state.subscriptionData.isLoading = false;
      state.subscriptionData.message = payload?.message;
    });

    builder.addCase(getPlateformSubscriptionPayloadData.pending, (state) => {
      state.platformSubscriptionData.isLoading = true;
      state.platformSubscriptionData.isSuccess = false;
    });
    builder.addCase(getPlateformSubscriptionPayloadData.fulfilled, (state, { payload }: any) => {
      state.platformSubscriptionData.isSuccess = true;
      state.platformSubscriptionData.isLoading = false;
      state.platformSubscriptionData.data = payload?.data;
      state.platformSubscriptionData.message = payload?.message;
    });
    builder.addCase(getPlateformSubscriptionPayloadData.rejected, (state, { payload }: any) => {
      state.platformSubscriptionData.data = payload;
      state.platformSubscriptionData.isSuccess = false;
      state.platformSubscriptionData.isLoading = false;
      state.platformSubscriptionData.message = payload?.message;
    });
  },
});

export const { clearPaymentSummary } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;