import React, { useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { isTablet } from "react-device-detect";

import { BiizlineLogo, SecureIcon } from "../../assets/images";
import { detectDevice } from "../../utils/helper";

const AuthLayout = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      navigate("/dashboard", { replace: true });
    }
    if (detectDevice()?.type !== "Desktop") {
      navigate("/home", { replace: true });
    }
  }, [token]);

  useEffect(() => {
    if (detectDevice()?.type === "Mobile") {
      if (detectDevice()?.os === "iOS") { ///iPad|iPhone|iPod/
        window.history.back();
        window.location.href = "https://apps.apple.com/in/app/biizline/id6599846985";
      } else {
        window.history.back();
        window.location.href = "https://biizline.com/coming-soon";
      }
    } else if (isTablet) {
      window.location.href = "https://biizline.com/coming-soon";
    }
  }, [window.location, detectDevice()?.type]);

  return (
    <>
      <div className="main-container container">
        {/* Header */}
        <div className="header-container flex justifyBetween alignCenter">
          <div className="logo-container flex">
            <Link to={"/home"}><img src={BiizlineLogo} alt="logo" loading="lazy" /></Link>
          </div>
          {/* <div className="flex gap-1 get-help-text"><HelpIcon /> Get Help</div> */}
        </div>

        <div className="auth-content">
          {/* <div className="auth-card"> */}
          <Outlet />
          {/* </div> */}
        </div>

        {/* <Footer />   */}

        <div className="footer-container flex justifyBetween alignCenter">
          <div className="safe-box flex alignCenter gap-1">
            <SecureIcon />
            <div className="text-box flex direction-column justifyCenter">
              <span className="secure-text">100% Secure</span>
              <span>Your Data Safety, Our Priority</span>
            </div>
          </div>
          <p className="copyright-text">&#169;2024 Biizline by Linics Enterprise Private Limited. All Rights Reserved</p>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;