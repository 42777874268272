import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import { message } from "antd";
import OneSignal from "react-onesignal";

import UserInfo from "../userInfo";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { selectCompany } from "../../redux/services/auth";
import { PlusIcons } from "../../assets/images";
import useMessageHook from "../../hooks/useMessageHook";
import { EditIcon, UserIcon } from "../../assets/images/icons";
import { detectDevice, getProfileIcon, getRestrictions } from "../../utils/helper";
import ConfirmationPopup from "../actionModals/confirmationPopup";
import { fetchTokenData, logoutDevice } from "../../redux/services/common";
import { clearAddressData } from "../../redux/slices/addressSlice";
import { getCompanyDetails } from "../../redux/services/company";
import { ALTER_TEXT, CHOOSE_COMPANY_POPUP, ERROR_STRINGS, LOGOUT_POPUP, SubscriptionActivityName, SubscriptionModules } from "../../utils/constant";
import { getSubscriptionPayloadData } from "../../redux/services/subscription";

interface Props {
  handleOpenChange: () => void;
  isSetting?: boolean;
}

const CompanyListAndSetting = ({ handleOpenChange, isSetting }: Props) => {
  const [isConfirmToggle, setIsConfirmToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyRestrictionData, setCompanyRestrictionData] = useState();
  const { showMessage, contextHolder } = useMessageHook();
  const { userDetails } = useAppSelector(state => state.common);
  const { subscriptionData, planDetailsSummary } = useAppSelector(state => state.subscription);
  const companyDetails = userDetails.data?.company;
  const { userId } = userDetails.data?.user || { userId: 0 };
  const fcmToken = localStorage.getItem("fcm_token");
  const token = localStorage.getItem("token");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { companyDetailList } = useAppSelector((state) => state.company);
  const createCompanyRestriction = companyRestrictionData && getRestrictions(companyRestrictionData, SubscriptionActivityName.COMPANY);

  const userData = userDetails.data?.user;
  const currentCompany = userDetails.data?.company;

  useEffect(() => {
    if (detectDevice()?.type === "Desktop" && userId && token) {
      dispatch(getSubscriptionPayloadData({ module: SubscriptionModules.COMPANY })).then((result) => {
        if (result?.payload?.status === 200) {
          setCompanyRestrictionData(result?.payload?.data);
        }
      }).catch((error) => console.error(error));
    }
  }, [dispatch, token, userId, planDetailsSummary, companyDetails]);

  const handleSwitchCompany = async (companyId: number, companyName: string) => {
    dispatch(selectCompany({ companyId: companyId, userId: (userData?.userId || 0) })).then((result) => {
      if (result?.payload?.status === 200) {
        localStorage.setItem("token", result.payload.data.token);
        localStorage.setItem("createNewCompany", JSON.stringify(false));
        setTimeout(() => {
          dispatch(getCompanyDetails());
          dispatch(fetchTokenData());
        }, 100);
        if (isSetting) {
          navigate(`/settings/${companyId}/company-details`, { state: { companyId: companyId } });
        } else {
          dispatch({ type: "RESET_STATE" });
          navigate("/dashboard");
        }
      } else {
        showMessage("error", result?.payload.message || "Something went wrong");
      }
    }).catch((error) => showMessage("error", ERROR_STRINGS.SOMETHING_WRONG));
    handleOpenChange();
  };

  const handleAddNewComapny = () => {
    dispatch(clearAddressData());
    localStorage.setItem("createNewCompany", JSON.stringify(true));
    navigate("/", { state: { phoneNo: currentCompany?.createdBy?.phoneNo } });
    handleOpenChange();
  };

  const goToSetting = (name: string) => {
    navigate(`/settings/${currentCompany?.companyId}/profile`, { state: { companyId: currentCompany?.companyId } });
    handleOpenChange();
  };

  const handleLogout = async () => {
    setLoading(true);
    dispatch({ type: "RESET_STATE" });
    const payload = {
      token: fcmToken,
      from: "Web",
    };
    if (userData?.userId) await dispatch(logoutDevice({ id: userData?.userId, payload })).then((result) => {
      dispatch({ type: "RESET_STATE" });
      if (result?.payload?.status === 200) {
        OneSignal.logout();
        message.success(result?.payload?.message);
        setTimeout(() => {
          localStorage.clear();
          navigate("/login");
        }, 50);
      } else {
        localStorage.clear();
        navigate("/login");
        setTimeout(() => {
          dispatch({ type: "RESET_STATE" });
        }, 50);
      }
    }).catch((error) => console.error(error)).finally(() => {
      dispatch({ type: "RESET_STATE" });
      setLoading(false);
    });
  };

  return (
    <div className="company-list-popover">
      {contextHolder}
      <div className="selected-company-box">
        <div className={`${isSetting ? "cursorPointer" : ""} company-box`} onClick={() => !isSetting ? {} : goToSetting(currentCompany?.name || "")}>
          <div className="user-box gap-2 flex alignCenter">
            <div className="img-box">
              {isSetting ?
                getProfileIcon(currentCompany?.createdBy?.firstName + " " + currentCompany?.createdBy?.lastName) : (
                  <>
                    {
                      (currentCompany && currentCompany?.profileThumbnailImg) ?
                        <img className="profile-img" src={currentCompany?.profileThumbnailImg} alt={ALTER_TEXT.PROFILE_IMAGE} /> : <UserIcon />
                    }
                  </>
                )}
            </div>
            <div className="customer-info flex direction-column">
              {<span>{isSetting ? (userData?.firstName + " " + userData?.lastName) : currentCompany?.name}</span>}
              {currentCompany?.district && <span className="user-name">{`${currentCompany?.district}, ${currentCompany?.state}`}</span>}
            </div>
          </div>
          {isSetting ? <button className="icon-button edit-icon" onClick={() => goToSetting(currentCompany?.name || "")}><EditIcon /></button> : <></>}
        </div>
      </div>
      {createCompanyRestriction && !isSetting ?
        <div className="switch-company-container">
          <div className="switch-company-box">
            {CHOOSE_COMPANY_POPUP.TITLE}
          </div>
        </div> : <></>}
      {userData && isSetting ?
        <div className="switch-company-container">
          <div className="switch-company-box">
            {CHOOSE_COMPANY_POPUP.TITLE_2}
          </div>
        </div> : <></>}
      {companyDetailList?.data && companyDetailList?.data?.filter((data) => isSetting ? data : data.companyId !== currentCompany?.companyId).map((data, index) => {
        return (
          <button key={index} className="switch-company-profile-container" onClick={() => handleSwitchCompany(data.companyId, data.name)}>
            <div className="inner-box flex alignCenter justifyBetween">
              <UserInfo
                profileImage={data.profileThumbnailImg || ""}
                comanyName={data.name}
                userName={`${data.district}, ${data.state}`}
              />
              {isSetting ? <button className="icon-button edit-icon" onClick={() => handleSwitchCompany(data.companyId, data.name)}><EditIcon /></button> : <></>}
            </div>
          </button>
        );
      })}
      {isSetting ?
        <button className="logout-button" onClick={() => setIsConfirmToggle(true)}>
          <span className="flex gap-2 alignCenter justifyCenter">
            <LogoutOutlined />
            <span className="button-text">{CHOOSE_COMPANY_POPUP?.LOGOUT}</span>
          </span>
        </button>
        :
        createCompanyRestriction ? <button className="add-company-container" onClick={handleAddNewComapny}>
          <div className="inner-box">
            <PlusIcons />
            {CHOOSE_COMPANY_POPUP.ADD_COMPANY}
          </div>
        </button> : <></>
      }
      <ConfirmationPopup
        open={isConfirmToggle}
        close={() => { setIsConfirmToggle(false); }}
        confirm={() => handleLogout()}
        infoContent={LOGOUT_POPUP.TITLE}
        cancelButtonText={LOGOUT_POPUP.NO}
        confirmButtonText={LOGOUT_POPUP.SUCCESS}
        disabled={loading}
      />
    </div>
  );
};

export default CompanyListAndSetting;