import React from "react";
import Skeleton from "react-loading-skeleton";

const SubscriptionPlanSkeleton = ({ height }: any) => {
  return (
    <div className="skeleton-wrapper" style={{ height: `${height}px` }}>
      <Skeleton />
    </div>
  );
};

export default SubscriptionPlanSkeleton;