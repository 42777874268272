import React, { useEffect } from "react";

import CommonTable from "./commonTable";
import { ACCOUNT_SETTINGS } from "../../utils/constant";
import Page from "../../components/page";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getNotificationModulesList } from "../../redux/services/accountSetting";
import { useAppSelector } from "../../hooks/useAppSelector";

const NotificationSettings = () => {
  const dispatch = useAppDispatch();
  const { notificationModulesList: data } = useAppSelector((state) => state.accountSetting);

  useEffect(() => {
    dispatch(getNotificationModulesList());
  }, [dispatch]);

  return (
    <Page
      title={[]}
      className="notification-settings-page"
    >
      <div className="notification-settings-header">
        <h3 className="title-3">{ACCOUNT_SETTINGS.NOTIFICATION_PAGE.TITLE}</h3>
        <p className="caption">{ACCOUNT_SETTINGS.NOTIFICATION_PAGE.DESCRIPTION}</p>
      </div>
      <div className="notification-settings-body">
        <div className="notification-settings-table">
          <CommonTable type="notification" isLoading={data?.isLoading} title={ACCOUNT_SETTINGS.NOTIFICATION_PAGE.TABLE_TITLE} data={data?.data} />
        </div>
      </div>
    </Page>
  );
};

export default NotificationSettings;