import React, { useEffect, useState } from "react";

import { handleKeyDown } from "../../utils/helper";

interface IProps {
  placeholder: string,
  name: string,
  label: string,
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
  value: string,
  maxLength?: number;
  className?: string;
  id?: string;
  required?: boolean;
  rows?: number;
}

export const Textarea = ({ placeholder, name, label, onChange, value, maxLength = 750, className, id, required, rows = 7 }: IProps) => {
  const [charCount, setCharCount] = useState(0);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    onChange(event);
    setCharCount(value?.length);
  };

  useEffect(() => {
    if(value){
      setCharCount(value?.length);
    }
  }, [value]);
  

  return (
    <div className={className ? `textarea-input ${className}` : "textarea-input"}>
      <div className="textarea-container">
        <label htmlFor={id}>
          <span className="flex gap-1">
            {label}
            {required && <span className="require-icon"> *</span>}
          </span>
          <textarea
            value={value}
            rows={rows}
            name={name}
            id={id}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            onChange={handleInputChange}
            maxLength={maxLength}
          />
        </label>
        <div className="char-count">{charCount}/{maxLength}</div>
      </div>
    </div>
  );
};