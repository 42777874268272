import React, { useState } from "react";

export const ActiveSwitch = ({ children }: any) => {
  const [active, setActive] = useState(false);
  return (
    <button className="active-switch-button" onClick={() => setActive(!active)}>
      <span className={children ? "switch-light draft" : active ? "switch-light active" : "switch-light inactive"}></span>
      <p className="switch-text">{!children ? active ? "Active" : "Inactive" : children}</p>
    </button>
  );
};
