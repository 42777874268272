import React from "react";
import { SortAscendingOutlined, SortDescendingOutlined, SwapOutlined } from "@ant-design/icons";

export const tableSortingIconToggle = ({ sortOrder }: any) => {
  return sortOrder === "ascend" ? (
    <SortAscendingOutlined style={{ color: "#000000E0" }} />
  ) : sortOrder === "descend" ? (
    <SortDescendingOutlined style={{ color: "#000000E0" }} />
  ) : (
    <SwapOutlined style={{ transform: "rotate(90deg)", color: "#000000E0" }} />
  );
};