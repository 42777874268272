import React from "react";

const EmailOutlinedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M1.33337 4.66797L6.77665 8.47826C7.21743 8.78681 7.43783 8.94108 7.67755 9.00084C7.8893 9.05362 8.11078 9.05362 8.32253 9.00084C8.56226 8.94108 8.78265 8.78681 9.22343 8.47826L14.6667 4.66797M4.53337 13.3346H11.4667C12.5868 13.3346 13.1469 13.3346 13.5747 13.1166C13.951 12.9249 14.257 12.6189 14.4487 12.2426C14.6667 11.8148 14.6667 11.2547 14.6667 10.1346V5.86797C14.6667 4.74786 14.6667 4.18781 14.4487 3.75999C14.257 3.38366 13.951 3.0777 13.5747 2.88596C13.1469 2.66797 12.5868 2.66797 11.4667 2.66797H4.53337C3.41327 2.66797 2.85322 2.66797 2.42539 2.88596C2.04907 3.0777 1.74311 3.38366 1.55136 3.75999C1.33337 4.18781 1.33337 4.74786 1.33337 5.86797V10.1346C1.33337 11.2547 1.33337 11.8148 1.55136 12.2426C1.74311 12.6189 2.04907 12.9249 2.42539 13.1166C2.85322 13.3346 3.41327 13.3346 4.53337 13.3346Z" stroke="#94A2B8" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default EmailOutlinedIcon;