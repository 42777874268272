import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

import endPoins from "./endPoints.json";
import axiosInstance from "../axios";

export const getAddressByPincode = createAsyncThunk("address/pincode", async (args: { pincode: string }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${endPoins.getAddressByPincode}/${args.pincode}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const addAddress = createAsyncThunk("address/add", async (payload: {
  addressLine: string;
  city: string;
  state: string;
  country: string | undefined;
  pincode: string;
  district: string | undefined;
}, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.addAddress, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getAddressListByCompany = createAsyncThunk("address/getList", async (args, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.getAddressByCompany);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error);
  }
});

export const setDefaultAddress = createAsyncThunk("address/setDefault", async (id: number, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.patch(`${endPoins.setDefaultAddress}/${id}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error);
  }
});

export const deleteDeliveryAddress = createAsyncThunk("address/delete", async (id: number, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.delete(`${endPoins.deleteAddress}/${id}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error);
  }
});

export const updateDeliveryAddress = createAsyncThunk("address/update", async (payload: any, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.patch(endPoins.updateAddress, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      message.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error);
  }
});

