import React, { useEffect, useState } from "react";
import { Spin, Table, TableProps } from "antd";

import CommonModal from "../../components/commonModal";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getOrderItemsByOrder } from "../../redux/services/orders";
import { useAppSelector } from "../../hooks/useAppSelector";
import { CheckedRounedOutlineIcon, NoImagesIcon } from "../../assets/images/icons";
import { dispatchOrderItemsTypes } from "../../types/ordersTypes";
import useMessageHook from "../../hooks/useMessageHook";
import InputWithDropdown from "../../components/inputWithDropdown";
import { clearOrderItemData } from "../../redux/slices/orders";
import { getSubscriptionPayloadData } from "../../redux/services/subscription";
import { SubscriptionActivityName, SubscriptionModules } from "../../utils/constant";
import { getRestrictions } from "../../utils/helper";

interface Props {
  open: boolean;
  close: () => void;
  orderId: number;
  handleConfirm: () => void;
  setDispatchOrderItems: (data: dispatchOrderItemsTypes[]) => void;
}

interface dispatchItemsTypes {
  orderItemId: number,
  productImage: string,
  productName: string,
  quantityOrdered: number,
  quantityDispatched: number,
  orderUnitConvertionUnit: string,
  orderUnit: string,
  quantityRemaining: number | string,
  productSize: string,
  productSizeUnit: string
}

const DispatchOrderModal = ({ open, close, orderId, handleConfirm, setDispatchOrderItems }: Props) => {
  const [dispatchItems, setDispatchItems] = useState<dispatchItemsTypes[]>([]);
  const [error, setError] = useState<any>({});
  const [dispatchSubscriptionData, setDispatchSubscriptionData] = useState<any[]>([]);
  const { showMessage, contextHolder } = useMessageHook();
  const dispatch = useAppDispatch();
  const { orderItems } = useAppSelector(state => state.manageOrders);
  const partialDispatchRestriction = dispatchSubscriptionData?.length && getRestrictions(dispatchSubscriptionData, SubscriptionActivityName.PARTIAL_DISPATCH);
  useEffect(() => {
    dispatch(getOrderItemsByOrder(orderId));
  }, [dispatch, orderId]);

  useEffect(() => {
    dispatch(getSubscriptionPayloadData({ module: SubscriptionModules.ORDER })).then((res) => {
      if (res?.payload?.status === 200) {
        setDispatchSubscriptionData(res?.payload?.data);
      }
    }).catch((error) => console.error(error));
  }, [dispatch]);

  useEffect(() => {
    const tempData = orderItems?.data?.map((item) => {
      return ({
        orderItemId: item.orderItemId,
        productImage: item.product.productImage?.thumbnailURL,
        productName: item.product.productName,
        quantityOrdered: item.quantityOrdered,
        quantityDispatched: item.quantityDispatched,
        orderUnitConvertionUnit: item.orderUnitConvertionUnit,
        orderUnit: item.orderUnit,
        quantityRemaining: item.quantityRemaining,
        isDispatched: item.quantityRemaining === 0 ? true : false,
        productSize: item.productSize.productSize,
        productSizeUnit: item.productSize.productSizeUnit,
      });
    });
    if (tempData?.length) {
      setDispatchItems(tempData);
    }
  }, [orderItems]);

  const handleQuntity = (orderItemId: number, value: number | string) => {
    if (value) {

      const tempData = dispatchItems?.map((item) => {
        if (orderItemId === item.orderItemId && (Number(value) > (item.quantityOrdered - item.quantityDispatched))) {
          setError((prev: any) => ({
            ...prev,
            [orderItemId]: true,
          }));
        } else {
          delete error[orderItemId];
        }
        if (item.orderItemId === orderItemId) {
          return ({ ...item, quantityRemaining: Number(value) });
        } else {
          return item;
        }
      });
      if (tempData) {
        setDispatchItems(tempData);
      }
    } else {
      const tempData = dispatchItems?.map((item) => {
        if (item.orderItemId === orderItemId) {
          return ({ ...item, quantityRemaining: "" });
        } else {
          return item;
        }
      });
      if (tempData) {
        setDispatchItems(tempData);
      }
    }
  };

  const handeSave = () => {
    if (Object.entries(error).length) {
      showMessage("error", "Please enter valid quantity");
      return;
    } else {
      const tempData = dispatchItems?.map((item) => { return ({ orderItemId: item.orderItemId, quantity: Number(item.quantityRemaining) }); });
      if (tempData) {
        setDispatchOrderItems(tempData);
        dispatch(clearOrderItemData());
        handleConfirm();
      }
    }
  };

  const columns: TableProps<any>["columns"] = [
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: 280,
      render: (text, record: any) => (
        <div className="product-name-box flex alignCenter gap-3">
          <div className="product-image-container">
            {record?.productImage ? <img className="product-image" src={record?.productImage} alt="product-img" /> : <NoImagesIcon />}
          </div>
          <div className="flex direction-column">
            <span className="product-name">{record?.productName}</span>
            {record?.quantityDispatched ? <span className="qty-dispatched">Total Dispatched Qty.: {record?.quantityDispatched}</span> : <></>}
          </div>
        </div>
      ),
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      width: 159,
      render: (text, record: any) => (
        <div className="size-text">{record?.productSize} {record?.productSizeUnit}</div>
      ),
    },
    {
      title: "Total Qty.",
      dataIndex: "qty",
      key: "qty",
      width: 131,
      render: (text, record: any) => (
        <div className="qty-text flex gap-1">
          <span className="quantity">{record?.quantityOrdered}</span>
          <span className="unit">{record?.orderUnitConvertionUnit ? record?.orderUnitConvertionUnit : record?.orderUnit}</span>
        </div>
      ),
    },
    {
      title: "Dispatch Qty.",
      dataIndex: "dispatchQty",
      key: "dispatchQty",
      width: 171,
      render: (text, record: any) => {
        const qtyUnit = record?.orderUnitConvertionUnit ? record?.orderUnitConvertionUnit : record?.orderUnit;
        return (
          <div className="flex direction-column">
            {!record.isDispatched ?
              <>
                <InputWithDropdown
                  onChange={partialDispatchRestriction ? (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (/^\d{0,5}$/.test(e.target.value)) {
                      handleQuntity(record.orderItemId, e.target.value);
                    }
                  } : (e) => e.preventDefault()}
                  suffixSelector={qtyUnit}
                  placeholder="Size (Ex. 100)"
                  value={record.quantityRemaining}
                  className={error[record.orderItemId] ? "error" : ""}
                  disabled={!partialDispatchRestriction}
                />
                {record?.quantityRemaining > (record?.quantityOrdered - record?.quantityDispatched) ? <span className="remaining-exceed-qty-text">Quantity exceeds</span> : record?.quantityRemaining !== record?.quantityOrdered ? <span className="remaining-qty-text">Remaining Qty.: {(record?.quantityOrdered - record?.quantityDispatched) - record?.quantityRemaining} {qtyUnit}</span> : <></>}
              </> : <div className="dispatched-text"><CheckedRounedOutlineIcon /> Dispatched</div>}
          </div>
        );
      },
    },
  ];

  const handleClose = () => {
    close();
    dispatch(clearOrderItemData());
  };

  const dispatchedData = dispatchItems.filter((item) => item.quantityOrdered !== item.quantityDispatched);
  const remainingQty = dispatchItems.filter((item) => (item.quantityRemaining === 0 || item.quantityRemaining === "") && item.quantityOrdered !== item.quantityDispatched);

  return (
    <CommonModal
      open={open}
      close={handleClose}
      closeIcon
      className="dispatch-order-modal"
      footer
      saveButtonText="Confirm"
      onSave={handeSave}
      disabled={remainingQty.length === (dispatchedData.length) ? true : false}
    >
      {contextHolder}
      <div className="dispatch-modal-container flex direction-column gap-5">
        <div className="header-container">
          <h3 className="title">Dispatch Order</h3>
          <span className="info-text">Please review the dispatch details below before confirming the order status change.</span>
        </div>
        <div className="dispatch-modal-content">
          {orderItems?.isLoading ? <div className="loading-container"><Spin /></div> : <></>}
          <Table
            columns={columns}
            dataSource={dispatchItems || []}
            className="dispatch-details-table"
            pagination={false}
            loading={orderItems.isLoading}
          // scroll={{ y: 300 }}
          />
        </div>
      </div>
    </CommonModal>
  );
};

export default DispatchOrderModal;