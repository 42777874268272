import React from "react";
import Skeleton from "react-loading-skeleton";

const SettingsCommonTableSkeleton = () => {
  return (
    <div className="table-row-skeleton">
      <Skeleton />
    </div>
  );
};

export default SettingsCommonTableSkeleton;