import { createSlice } from "@reduxjs/toolkit";

import { getOtpforUpdate, getProfileDetails, updateVerifyOtp } from "../services/settings";
import { userProfile } from "../../types/settingTypes";

interface IProps {
  userProfile: {
    isLoading: boolean,
    isSuccess: boolean,
    data: userProfile | null,
    message: string
  };
  otpSend: {
    message: string,
    isLoading: boolean,
    isSuccess: boolean,
  };
  otpVerify: {
    message: string,
    isLoading: boolean,
    isSuccess: boolean,
  };
}

const initialState: IProps = {
  userProfile: {
    isLoading: false,
    isSuccess: false,
    data: null,
    message: "",
  },
  otpSend: {
    message: "",
    isLoading: false,
    isSuccess: false,
  },
  otpVerify: {
    message: "",
    isLoading: false,
    isSuccess: false,
  },
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    clearOTPData: (state) => {
      state.otpSend = initialState.otpSend;
      state.otpVerify = initialState.otpVerify;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileDetails.pending, (state) => {
      state.userProfile.isLoading = true;
      state.userProfile.isSuccess = false;
    });
    builder.addCase(getProfileDetails.fulfilled, (state, { payload }: any) => {
      state.userProfile.isSuccess = true;
      state.userProfile.isLoading = false;
      state.userProfile.data = payload?.data;
      state.userProfile.message = payload?.message;
    });
    builder.addCase(getProfileDetails.rejected, (state, { payload }: any) => {
      state.userProfile.data = payload;
      state.userProfile.isSuccess = false;
      state.userProfile.isLoading = false;
      state.userProfile.message = payload?.message;
    });

    builder.addCase(getOtpforUpdate.pending, (state) => {
      state.otpSend.isLoading = true;
      state.otpSend.isSuccess = false;
    });
    builder.addCase(getOtpforUpdate.fulfilled, (state, { payload }: any) => {
      state.otpSend.isSuccess = true;
      state.otpSend.isLoading = false;
      state.otpSend.message = payload?.message;
    });
    builder.addCase(getOtpforUpdate.rejected, (state, { payload }: any) => {
      state.otpSend.isSuccess = false;
      state.otpSend.isLoading = false;
      state.otpSend.message = payload?.message;
    });

    builder.addCase(updateVerifyOtp.pending, (state) => {
      state.otpVerify.isLoading = true;
      state.otpVerify.isSuccess = false;
    });
    builder.addCase(updateVerifyOtp.fulfilled, (state, { payload }: any) => {
      state.otpVerify.isSuccess = true;
      state.otpVerify.isLoading = false;
      state.otpVerify.message = payload?.message;
    });
    builder.addCase(updateVerifyOtp.rejected, (state, { payload }: any) => {
      state.otpVerify.isSuccess = false;
      state.otpVerify.isLoading = false;
      state.otpVerify.message = payload?.message;
    });
  },
});

export const { clearOTPData } = settingSlice.actions;
export default settingSlice.reducer;