import { createSlice } from "@reduxjs/toolkit";

import { getAllStatCount } from "../services/dashboard";

interface DashboardSliceProps {
  statCount: StatCountProps
}

interface StatCountProps {
  isLoading: boolean;
  isSuccess: boolean;
  message: string;
  data: {
    connectionCountData: {
      connectionPercentageWeeklyChange: number;
      totalConnectionsCount: number;
    },
    customerCountData: {
      totalCustomerCount: number;
    },
    productCountData: {
      totalProductCount: number;
      productPercentageChangeWeekly: number;
    },
    orderCountData: {
      totalOrderCount: number,
      orderPercentageChangeWeekly: number
    },
  } | null;
}

const initialState: DashboardSliceProps = {
  statCount: {
    isLoading: false,
    isSuccess: false,
    message: "",
    data: null,
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllStatCount.pending, (state) => {
      state.statCount.isLoading = true;
      state.statCount.isSuccess = false;
    });
    builder.addCase(getAllStatCount.fulfilled, (state, { payload }: any) => {
      state.statCount.isSuccess = true;
      state.statCount.isLoading = false;
      state.statCount.data = payload?.data;
      state.statCount.message = payload?.message;
    });
    builder.addCase(getAllStatCount.rejected, (state, { payload }: any) => {
      state.statCount.data = payload;
      state.statCount.isSuccess = false;
      state.statCount.isLoading = false;
      state.statCount.message = payload?.message;
    });
  },
});

export default dashboardSlice.reducer;