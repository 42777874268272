import React from "react";

const Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="30" viewBox="0 0 1024 1024" fill="none">
      <rect width="1024" height="1024" rx="204.8" fill="#FFBC0D" />
      <rect x="343" y="250.875" width="143.36" height="143.36" rx="71.68" fill="white" />
      <path d="M414.8 320.3C414.8 301.743 429.843 286.7 448.4 286.7C466.957 286.7 482 301.743 482 320.3C482 338.857 466.957 353.9 448.4 353.9C429.843 353.9 414.8 338.857 414.8 320.3Z" fill="black" />
      <rect x="368.6" y="404.475" width="92.16" height="368.64" rx="46.08" fill="black" />
      <rect width="143.36" height="143.36" rx="71.68" transform="matrix(-1 0 0 1 680.92 250.875)" fill="white" />
      <path d="M609.12 320.3C609.12 301.743 594.077 286.7 575.52 286.7C556.963 286.7 541.92 301.743 541.92 320.3C541.92 338.857 556.963 353.9 575.52 353.9C594.077 353.9 609.12 338.857 609.12 320.3Z" fill="black" />
      <rect x="563.16" y="404.475" width="92.16" height="368.64" rx="46.08" fill="black" />
    </svg>
  );
};

export default Logo;

{/* <svg xmlns="http://www.w3.org/2000/svg" width="23" height="30" viewBox="0 0 23 30" fill="none">
  <rect x="0.879883" y="23.6304" width="20.4895" height="8.95337" rx="1.92" transform="rotate(-45 0.879883 23.6304)" fill="#41B7F3" />
  <path d="M15.7607 21.418L2.16808 7.8253C1.41827 7.0755 1.41827 5.85982 2.16808 5.11002L5.8817 1.39639C6.63151 0.646586 7.84718 0.646587 8.59699 1.39639L20.832 13.6314C21.5818 14.3812 21.5818 15.5969 20.832 16.3467L15.7607 21.418Z" fill="#000000" />
  <path d="M8.31055 16.2047L15.7622 21.4208L9.42829 15.0869L8.31055 16.2047Z" fill="#003169" />
</svg> */}