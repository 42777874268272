import React from "react";

const OfferBG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
      <path d="M44.2393 26.2229L46.7668 23.8744L44.3802 21.383L46.298 18.5151L43.4011 16.6411L44.6057 13.4082L41.3547 12.2529L41.7814 8.82926L38.3516 8.45489L37.9772 5.02511L34.5536 5.45178L33.3983 2.20083L30.1654 3.40541L28.2914 0.508519L25.4235 2.42627L22.9321 0.0396729L20.5836 2.56716L17.6089 0.819398L15.9067 3.82025L12.6091 2.8057L11.6448 6.11833L8.20219 5.89145L8.02778 9.33712L4.62564 9.91038L5.25056 13.3034L2.07232 14.6458L3.46287 17.8033L0.679961 19.8423L2.76118 22.5941L0.523438 25.22L3.18315 27.4176L1.61133 30.4888L4.70621 32.0136L3.88493 35.3646L7.24806 36.1347L7.22186 39.5846L10.6718 39.5584L11.4419 42.9216L14.7929 42.1003L16.3177 45.1952L19.3889 43.6233L21.5865 46.2831L24.2124 44.0453L26.9642 46.1265L29.0032 43.3435L32.1607 44.7342L33.5031 41.5559L36.8961 42.1809L37.4693 38.7787L40.915 38.6043L40.6882 35.1617L44.0008 34.1974L42.9862 30.8998L45.9871 29.1976L44.2393 26.2229Z" fill="url(#paint0_linear_2134_9118)" stroke="white" strokeWidth="0.36756" strokeLinejoin="bevel" />
      <defs>
        <linearGradient id="paint0_linear_2134_9118" x1="20.324" y1="42.7053" x2="40.9211" y2="7.88269" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF6243" />
          <stop offset="1" stopColor="#F61733" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default OfferBG;