import React from "react";

import { CheckedRounedOutlineIcon } from "../../assets/images/icons";

const OrderStatusButtons = ({ status }: { status: string }) => {
  return (
    <div className="order-status-container">
      {status === "In Progress" ? <span className="inprogress-status">{status}</span> : ""}
      {status === "Accepted" ? <span className="accepted-status">{status}</span> : ""}
      {status === "Pending" ? <span className="pending-status">{status}</span> : ""}
      {status === "Dispatched" ? <div className="flex alignCenter gap-1"><CheckedRounedOutlineIcon /><span className="pending-status">{status}</span></div> : ""}
      {status === "Dispatched" ? <div className="flex alignCenter gap-1"><CheckedRounedOutlineIcon /><span className="dispatch-status">{status}</span></div> : ""}
      {status === "Partially Dispatched" ? <div className="flex alignCenter gap-1"><CheckedRounedOutlineIcon /><span className="dispatch-status">{status}</span></div> : ""}
    </div>
  );
};

export default OrderStatusButtons;