import { createAsyncThunk } from "@reduxjs/toolkit";

import endPoins from "./endPoints.json";
import axiosInstance from "../axios";

export const getUserModulesActivity: any = createAsyncThunk("get/userModuleActivity", async (args, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(endPoins.userRoleModuleActivity);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getUserRoleList: any = createAsyncThunk("rolePermission/getUserRoleList", async (args, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(endPoins.userRoleList);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getUserRoleDetails: any = createAsyncThunk("get/userRoleDetails", async (id: string, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${endPoins.getUserRoleDetails}/${id}`);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const createUserRolePermission: any = createAsyncThunk("create/userModuleActivity", async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.createUserRolePermission, payload);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error.message);
  }
});

export const getRoleAccessData = createAsyncThunk("rolePermission/getRoleAccessPayload", async (args, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(endPoins.rolePermissionData);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error);
  }
});

export const getOrederSeriesData = createAsyncThunk("company/getPrefixSuffix", async (args, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(endPoins.getPrefixSuffix);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error);
  }
});

export const setPrefixSuffix = createAsyncThunk("company/updatePrefixSuffix", async (args: { orderNumberPrefix: string; orderNumberSuffix: string; financialYearEnd: string; financialYearStart: string; }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.put(endPoins.updatePrefixSuffix, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error);
  }
});

export const getProductsManagementData = createAsyncThunk("company/getProductManagement", async (args, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(endPoins.getProductManagementList);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error);
  }
});
export const updateProductManagementData = createAsyncThunk("company/updateProductManagement", async (args: {
  productAccountSettingId: number, 
  isActive: boolean
}, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.patch(endPoins.updateProductManagement, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error);
  }
});

// Connection Settings
export const getBlockedConnectionsList = createAsyncThunk("connection/block-connection", async (args, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(endPoins.blockedConnectionsList);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error);
  }
});

// Notification Settings
export const getNotificationModulesList = createAsyncThunk("company/getUpdateNotificationManagement", async (args, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(endPoins.getNotificationtList);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error);
  }
});

export const updateNotificationManagementData = createAsyncThunk("company/updateNotificationManagement", async (args: {
  notificationSettingId: number, 
  isActive: boolean
}, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.patch(endPoins.updateNotificationManagement, args);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue(error);
  }
});