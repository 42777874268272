import React from "react";
import { Modal, Spin } from "antd";

interface Props {
  onSave?: () => void
  close: () => void,
  onCancel?: () => void,
  header?: string,
  info?: string,
  open: boolean,
  children: React.ReactNode,
  className?: string,
  footer?: boolean,
  closeIcon?: boolean,
  cancelButtonText?: string
  saveButtonText?: string;
  rootClassName?: string;
  loading?: boolean;
  disabled?: boolean;
  saveBtn?: boolean
}

const CommonModal = ({
  cancelButtonText = "Cancel",
  saveButtonText = "Save",
  header,
  info,
  closeIcon = false,
  footer = false,
  open,
  close,
  children,
  className,
  onSave,
  onCancel,
  rootClassName,
  loading = false,
  disabled = false,
  saveBtn = false,
}: Props) => {
  return (
    <Modal
      centered
      maskClosable={false}
      open={open}
      closeIcon={closeIcon}
      okText="Save"
      onCancel={close}
      rootClassName={rootClassName}
      className={"transparent-card common-modal-container " + className}
      keyboard={false}
      footer={footer ? (
        saveBtn ?
          <div className="flex gap-3 justifyEnd">
            <button
              type="button"
              onClick={onSave}
              className="primary rounded-14 large40 modal-button"
              disabled={loading || disabled}
            >
              {loading ? <><Spin /> Saving...</> : saveButtonText}
            </button>
          </div> :
          <div className="flex gap-3 justifyEnd">
            <button
              onClick={onCancel || close}
              className="primary secondary rounded-14 large40 modal-button"
              disabled={loading || disabled}
            >
              {cancelButtonText}
            </button>
            <button
              type="button"
              onClick={onSave}
              className="primary rounded-14 large40 modal-button"
              disabled={loading || disabled}
            >
              {loading ? <><Spin /> Saving...</> : saveButtonText}
            </button>
          </div>
      ) : null}
    >
      {header && <h1 className="header-title">{header}</h1>}
      {info && <p className="header-info">{info}</p>}
      {children}
    </Modal>
  );
};

export default CommonModal;