import { Input, InputRef } from "antd";
import React, { useEffect, useRef } from "react";

import { handleKeyDown } from "../../utils/helper";


interface SizeInputProps {
  disabled?: boolean,
  name?: string,
  autofocus?: boolean,
  required?: boolean,
  label?: string;
  className?: string;
  placeholder?: string;
  value?: string | number;
  prefixSelector?: React.ReactNode | string;
  suffixSelector?: React.ReactNode | string;
  onChange: (value: React.ChangeEvent<HTMLInputElement> | any) => void;
  previewText?: string;
  maxLength?: number;
  suffix?: string;
  prefix?: any;
  bottomValue?: string;
  onFocus?: any;
  autoFocus?: boolean;
  onBlur?: () => void;
}

const InputWithDropdown: React.FC<SizeInputProps> = ({
  disabled = false,
  name,
  autofocus = false,
  required = false,
  label,
  className,
  placeholder = "Size (Ex. 100)",
  value,
  prefixSelector,
  suffixSelector,
  onChange,
  maxLength = 50,
  suffix,
  prefix,
  bottomValue,
  onFocus,
  autoFocus = false,
  onBlur,
}) => {
  const inputRef = useRef<InputRef | null>(null);

  useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autofocus]);

  function widthGenerate(e: any) {
    const numberOfCharacters: any = e?.target?.value?.length;
    if (numberOfCharacters) {
      e.target?.classList?.add("focused");
      e.target.style.width = `calc(${numberOfCharacters}ch + 10px)`;
    } else {
      e.target?.classList?.remove("focused");
      e.target.style.width = "4ch";
    }
  }

  function resetWidth(e: any) {
    e.target?.classList?.remove("focused");
    e.target.style.width = "100%";
  }

  return (
    <div className={className + " antd-input"}>
      <label htmlFor="">
        <span className="size-label flex gap-1">{label}{required && <span className="require-icon"> *</span>}</span>
        {/* Input field */}
        <Input
          disabled={disabled}
          ref={inputRef}
          addonBefore={prefixSelector}
          addonAfter={suffixSelector}
          placeholder={placeholder}
          value={value}
          onChange={(e) => { onChange(e); }}
          onKeyDown={handleKeyDown}
          maxLength={maxLength}
          name={name}
          onInput={(e:any) => {
            if(e.target?.value && suffix){
              widthGenerate(e);
            }else {
              resetWidth(e);
            }
          }}
          autoFocus={autoFocus}
          autoComplete="off"
          suffix={suffix}
          // onBlur={onBlur}
          className={suffix ? "suffix-input" : ""}
          // onFocus={handleFocus}
          prefix={prefix}
          style={{ transition: "width 0.0s ease", minWidth: "60px" }} // Smooth width change
        />
      </label>
      {bottomValue && <span className="size-label flex gap-1">{bottomValue}</span>}
    </div>
  );
};

export default InputWithDropdown;